import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Companylogo from "../../content/images/icons/bmw-logo.png";

function ProjectCard({project}:any) {
  return (
    <Link to={`${Cookies.get('userData') === 'agent' ? `/agent/projects/${project?.id}` : `/company/projects/${project?.id}`}`} className="project-card card relative flex flex-col p-5 cursor-pointer">
        <div className="relative w-full h-full flex flex-col justify-between">
            <div className="flex gap-4 items-center">
                <div className="w-[3rem] h-[3rem] rounded-full">
                    <img src={Companylogo} alt="company-logo" className="w-full rounded-full" />
                </div>
                <div className="text-left">
                    <p className="text-h5 font-bold max-w-[16rem]">{project?.type === "Vacancy" ? project?.role : project?.name}</p>
                    <p className="text-p">{project?.company}</p>
                </div>
            </div>
            <div className="flex text-left justify-between text-p">
                <p className="text-[var(--soft-text)]">{project?.type === "Vacancy" ? project?.minWage + "$ | " + project?.method : project?.area}</p>
                <p className={`text-h5 ${project?.type === "Vacancy" ? "text-green" : "text-blue"}`}>{project?.type}</p>
            </div>
        </div>
    </Link>
  );
}

export default ProjectCard;