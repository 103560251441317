// async function fetchUserData(): Promise<any> {
//     const response = await fetch("http://192.168.2.125:3001/user");
  
//     if (!response.ok) {
//       throw new Error("Failed to fetch user data");
//     }
  
//     const data = await response.json();
//     return data;
// }
  
// export default fetchUserData;

import Cookies from 'js-cookie';
import users from '../../jsons/users.json';
import companies from '../../jsons/company.json';

async function fetchUserData(): Promise<any> {
    if (Cookies.get('userData') === "agent") 
        return users.users[0];
    else
        return companies.company[0];
}
  
export default fetchUserData;

