import axios from "axios";

const URL = process.env.URL+"/companies"

const GetCompany = (id) => {
    axios
        .get(URL + `/${id}`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful get company response here
            // To Project page
            /**
             * {
             *   "id": 1,
             *   "companyName": "Example Company",
             *   "description": "We specialize in software development",
             *   "projectsCreated": 5,
             *   "plan": "NORMAL"
             * }
             */
        })
        .catch((error) => {
            console.error("Get company failed:", error);
            // Handle login error here
        });
};

const GetCompanies = (limit, offset) => {
    axios
        .get(URL, {params: {limit, offset}})
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful get companies response here
            // To Project page
            /**
             * [
             *   {
             *     "id": 1,
             *     "companyName": "Example Company",
             *     "description": "We specialize in software development",
             *     "projectsCreated": 5,
             *     "plan": "NORMAL"
             *   }
             * ]
             */
        })
        .catch((error) => {
            console.error("Get companies failed:", error);
            // Handle login error here
        });
};

const DeleteCompany = (id) => {
    axios
        .delete(URL + `/${id}`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful delete company response here
            // To Project page
            /**
             * If success, return 204
             */
        })
        .catch((error) => {
            console.error("Delete company failed:", error);
            // Handle login error here
        });
};

const CreateCompany = (companyName, description, plan, Company_id) => {
    axios
        .post(URL, { companyName, description, plan, Company_id })
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful create company response here
            // To Project page
            /**
             * If success, return 201
             */
        })
        .catch((error) => {
            console.error("Create company failed:", error);
            // Handle login error here
        });
};

const AddMemberToCompany = (memberData, onSuccess, onError) => {
    axios
        .post('/api/company/update', memberData)
        .then((res) => {
            if (res.data.success) {
                onSuccess(res);
            } else {
                onError(res);
            }
        })
        .catch((error) => {
            console.error("Error adding member:", error);
            onError(error);
        });
};

const UpdateCompany = (
    { companyName, companySector, email, country, city, phone },
    onSuccess,
    onError
  ) => {
    axios
      .post(URL, { companyName, companySector, email, country, city, phone })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          onSuccess(res);
        } else {
          onError(res);
        }
      })
      .catch((error) => {
        console.error("Update User failed:", error);
        onError(error);
      });
  };

const UpdateCompanyPassword = (
    { newPass, confirmNewPass },
    onSuccess,
    onError
    ) => {
    axios
        .post(URL, { newPass, confirmNewPass })
        .then((res) => {
        console.log(res);
        if (res.status === 201) {
            onSuccess(res);
        } else {
            onError(res);
        }
        })
        .catch((error) => {
        console.error("Update User failed:", error);
        onError(error);
        });
};

export const getCompany = GetCompany;
export const getCompanies = GetCompanies;
export const deleteCompany = DeleteCompany;
export const createCompany = CreateCompany;
export const addMemberToCompany = AddMemberToCompany;
export const updateCompany = UpdateCompany;
export const updateCompanyPassword = UpdateCompanyPassword;