import React, { useState } from "react";
import './eval.css';

import HeaderTop from '../../components/HeaderTop/header-top';
import formExample from './example.json';
import Prompt from "../../components/Prompt/prompt";
// import { useNavigate } from "react-router-dom";
import { SubmitProjectEval } from "../../services/projects.js";

function EvalPage() {
	let formAnswers = new Map();
	const [answered, setAnswered] = useState(0);
	const [emptyWork, setEmptyWork] = useState(false);
	// const navigate = useNavigate();

	// let error = document.getElementById('eval-error-msg') as HTMLParagraphElement;

	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	const question = (answer:boolean) => {
		if (answer) {
			setEmptyWork(true);
			return ;
		}
		setPrompt({
			message: "",
			load: false,
			action: ""
		});
	}
	
	function nextQuestion(formId: number) {
		let question = document.getElementById('form-'.concat((formId + 1).toString()));
		if (question !== null) {
			question?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'start'
			});
		} else {
			document.getElementById('finish-eval')?.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'start'
			});
		}
	}

	function clickYes(question: string, id: number) {
		if (!formAnswers.get(question))
			setAnswered(answered + 1);
		formAnswers.set(question, "yes");
		document.getElementById('project-eval-btn-yes-'.concat(id.toString()))?.classList.add('clicked');
		document.getElementById('project-eval-btn-no-'.concat(id.toString()))?.classList.remove('clicked');
		nextQuestion(id);
	}

	function clickNo(question: string, id: number) {
		if (!formAnswers.get(question))
			setAnswered(answered + 1);
		formAnswers.set(question, "no");
		document.getElementById('project-eval-btn-yes-'.concat(id.toString()))?.classList.remove('clicked');
		document.getElementById('project-eval-btn-no-'.concat(id.toString()))?.classList.add('clicked');
		nextQuestion(id);
	}

	function submitEval() {
		let comment = document.getElementById('eval-page-comment') as HTMLTextAreaElement;
		if (formExample.form.length > answered) {
			const errorMsgElement = document.getElementById('eval-page-error-msg');
			if (errorMsgElement) {
				errorMsgElement.innerText = 'Please complete the evaluation form.';
			}
			return;
		}

		if (comment.value === '') {
			const errorMsgElement = document.getElementById('eval-page-error-msg');
			if (errorMsgElement) {
				errorMsgElement.innerText = 'Please write a comment about the agent`s project.';
			}
			return;
		}

		SubmitProjectEval(formAnswers, emptyWork, comment.value)
	}

	function submitEmptyWork() {
		setPrompt({
			message: "Are you sure you want to add a 'Empty work' flag?",
			load: true,
			action: "save"
		});
	}

	return (
		<div className="projects-page relative flex flex-col justify-start items-start w-full pl-[5vw] pr-4">
			<HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null}/>
			<h1 className="text-h3 mb-[6rem]">Evaluation sheet</h1>
			<div className="slider-ele w-full text-[var(--text)] px-[8vw]  gap-4 flex flex-col text-left ">
				<p className="mb-10 text-h3">Welcome to the evaluation phase! Before you embark on the process of evaluating your fellow agents, please review the guidelines and rules below to ensure a fair and constructive review process.</p>
				<div className="flex flex-col gap-4">
					<h5 className="text-h4 text-yellow">General rules</h5>
					<ul className="flex flex-col gap-2">
						<li><b>Honesty is Key:</b> Provide feedback based on the merits of the project, not personal biases or unrelated factors.</li>
						<li><b>Constructive Criticism:</b> If you find areas of improvement, frame your feedback in a positive manner. Avoid negative or derogatory remarks.</li>
						<li><b>Objectivity:</b> Ensure that your evaluations are unbiased. Avoid evaluating projects of agents you have close personal ties with.</li>
						<li><b>Confidentiality:</b> Do not share or disclose any information about the projects you review. Respect the intellectual property of your peers.</li>
					</ul>	
				</div>		
				<div className="flex flex-col gap-4 mt-10">
					<h5 className="text-h4 text-yellow">Final Note</h5>
					<p>
						Every agent puts in effort and time into their projects. 
						It's essential to respect that effort and provide evaluations that are genuine, 
						fair, and beneficial for the growth of the individual and the Worklab community.
					</p>
				</div>		
			</div>
			<div className="slider-ele w-full text-[var(--text)] px-[8vw]  gap-4 flex mt-10 flex-col text-left ">
				<h5 className="text-h4 text-yellow">Project</h5>
				<div className="w-full flex gap-4">
					<div className="edit-project-input relative flex flex-col">
						<h4 className="text-h5 text-left w-full mb-2">Subject:</h4>
						<button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">open</button>
					</div>
					<div className="edit-project-input relative flex flex-col">
						<h4 className="text-h5 text-left w-full mb-2">Agent`s project:</h4>
						<button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">download</button>
					</div>
				</div>
			</div>
			<div className="project-card-line mt-10"></div>
			{formExample.form.map((question: any) => (
				<div id={'form-' + question.id} className='slider-ele text-[var(--text)] px-[8vw] my-[2rem] py-[3rem]' key={question.id} >
					<h2 className='relative w-full text-h3 text-left text-[var(--text)] mb-10'>{question.question}</h2>
					<div className="relative flex ">
						<button id={"project-eval-btn-yes-" + question.id} onClick={() => clickYes(question.question, question.id)} className="project-eval-btn brightness-[.6] bg-blue w-[7rem] py-2">yes</button>
						<button id={"project-eval-btn-no-" + question.id} onClick={() => clickNo(question.question, question.id)} className="project-eval-btn brightness-[.6] bg-blue w-[7rem] py-2">no</button>
					</div>
				</div>
			))}
			<div id="finish-eval" className="slider-ele w-full text-[var(--text)] px-[8vw] my-[2rem] py-[3rem]">
				<p className="text-h3 mb-5 text-left">Leave a comment about the evaluation</p>
				<textarea id="eval-page-comment" name="comment"
					className="resize-none bg-[transparent] border-2 border-[var(--text)] rounded-[18px] p-3 outline-none w-full h-40"
					placeholder="| Write here" cols={30} rows={10}>
				</textarea>
				<p id="eval-page-error-msg" className="text-left w-full text-red"></p>
				<div className="w-full flex justify-start gap-4 ">
					<button id="eval-page-submit" onClick={() => submitEval()} className="p-2 bg-green mt-5 rounded-[10px] text-white">Submit evaluation</button>
					<button id="eval-page-submit" onClick={() => submitEmptyWork()} className="p-2 bg-red mt-5 rounded-[10px] text-white ">Empty work</button>
				</div>
				<p id="eval-error-msg" className="text-red"></p>
				<div className="relative flex w-full">
					<button id="go-to-top" onClick={() => window.scrollTo({top:0,behavior: "smooth"})} className="text-left mt-6 text-white underline">Go to the top</button>
				</div>
			</div>
			<Prompt onDialog={question} opened={prompt.load} message={prompt.message} type={"confirm"}/>
		</div>
	);
}

export default EvalPage;
