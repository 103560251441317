import ProjectCard from '../../components/Projects/project-card';
import ProjectList from "../../jsons/project.json";

import { useLocation } from 'react-router-dom';
// import { GetProjects } from '../../services/projects';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Section({ section, search }:{section:number, search:string}) {
	const query = useQuery();

	const projectType = query.get('projectType') || "All";
	const peopleDoing = query.get('peopleDoing') || "Default";
	const deadline = query.get('deadline') || "Default";

	// TODO: fix this
	const projectsBeforeFilter = ProjectList.projects;
	// const projectsBeforeFilter = GetProjects();
	
    if (!projectsBeforeFilter)
		return null;

	let project = projectsBeforeFilter;
	
	if (projectType !== 'All')
		project = projectsBeforeFilter.filter(p => projectType === 'All' || p?.type === projectType);
	
	// Filter people
	if (peopleDoing !== 'Default') 
		project = project.filter(p => peopleDoing === "less" ? p.participants <= 10 : p.participants > 10);

	// Convert to date
	const convertToDate = (deadlineStr: string): Date => {
		const [day, month, year] = deadlineStr.split('.').map(Number);
		return new Date(`20${year}-${month}-${day}`);
	}

	// Filter deadline
	if (deadline !== 'Default') {
		const currentDate = new Date();
		const oneMonthFromNow = new Date();
		oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
	
		project = project.filter(p => {
			const projectDeadlineDate = convertToDate(p.deadline);
			return projectDeadlineDate > oneMonthFromNow;
		});
	}

	// Filter based on search
	if (search !== '')
    	project = project.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));
	

	if (section === 1) {
		// Current projects
		return (
			<>
			{ project?.filter((project: any) => project?.subscribed && !project?.done).length !== 0 ?
			  project?.filter((project: any) => project?.subscribed && !project?.done).map((project: any) => {
				return (<ProjectCard
					key={project?.id || 0}
					project={project}
				/>);
			  })
			  :
			  <p>No projects found</p>
			}
		  </>
		);
	} else {
		// Done projects
		return (
			<>
				{   project?.filter((project: any) => project?.subscribed && project?.done).length !== 0 ?
			  		project?.filter((project: any) => project?.subscribed && project?.done).map((project: any) => {
					    return (<ProjectCard
                            key={project?.id || 0}
							project={project}
						/>);
				    }) :
                    <p>No projects found</p>
                }
			</>
		);
	}
}