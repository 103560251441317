import React from "react";
import './courses.css';

import CoursesList from '../../jsons/courses.json';
import CoursesCard from "./courseCard";
import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Section({section, search}:{section:number, search:string}) {
	let courses = CoursesList.courses;

    const query = useQuery();

	const companyName = query.get('companyName') || "All";
	const area = query.get('area') || "All";
	const levelOfWork = query.get('workLevel') || "All";

    courses = courses.filter(course => course.description.toLowerCase().includes(search.toLowerCase()));

    if (companyName && companyName !== 'All') {
        courses = courses.filter(course => course.company.toLowerCase().includes(companyName.toLowerCase()));
    }
    if (area && area !== 'All') {
        courses = courses.filter(course => course.area.toLowerCase().includes(area.toLowerCase()));
    }
    if (levelOfWork && levelOfWork !== 'All') {
        courses = courses.filter(course => course.levelOfWork === levelOfWork);
    }

    if (section === 2) {
        // Section 2 - All courses
        return renderCourses(courses);
    } else {
        // Section 1 - Recommended courses
        const recommendedCourses = courses.filter(course => course.recommended);
        return renderCourses(recommendedCourses);
    }
}

function renderCourses(courses:any) {
    return (
        <>
            {
                courses.length === 0 ?
                <div className="w-full flex justify-center items-center">
                    <p className="text-[var(--soft-text)]">No courses available</p>
                </div>
                :
                <div className="courses-list-grid relative">
                    {
                        courses.map((course:any) => (
                            <CoursesCard 
                                key={course.id}
                                company={course.company || "Loading..."} 
                                about={course.description || "Loading..."}
                                img={course.imagePath || "..."} 
                                participants={course.peopleDoing || "..."}/>
                        ))
                    }
                </div>
            }
        </>
    );
}

export default Section;