import React, { useEffect, useState } from "react";
import './projects.css';

import { Country, State } from 'country-state-city';

import ProjectData from "../../jsons/project.json";
import HeaderTop from "../../components/HeaderTop/header-top";

import Prompt from "../../components/Prompt/prompt";
import { useParams } from "react-router-dom";
import { updateProject } from "../../services/projects";

function EditProject() {

	const { projectId = "1" } = useParams<{ projectId?: string }>();
	
	const [project, setProject] = React.useState<any>(null);

	React.useEffect(() => {
		const index = parseInt(projectId, 10) - 1;
		setProject(ProjectData.projects[index]);
	}, [projectId]);
	
	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: "",
	});

	const question = (answer:boolean) => {
		if (answer) {
			window.history.back();
		}
		setPrompt({
			message: "",
			load: false,
			action: ""
		});
	}

	const [type, setType] = useState("Vacancy");
	const [projectName, setProjectName] = useState("");
	const [projectDescr, setProjectDescr] = useState("");
	const [area, setArea] = useState("");
	const [techn, setTech] = useState("");
	const [projectDeadline, setDeadline] = useState("");
	const [role, setRole] = useState("");
	const [country, setCountry] = useState("");
	const [city, setCity] = useState("");
	const [contract, setContract] = useState("Part-time");
	const [minWage, setMinWage] = useState(0);
	const [maxWage, setMaxWage] = useState(0);
	const [method, setMethod] = useState("Hybrid");
	const [fileContent, setFileContent] = useState<File | null>(null);

	useEffect(() => {
		setType(project?.type || "Vacancy");
		setProjectName(project?.name || "");
		setProjectDescr(project?.descr || "");
		setArea(project?.area || "");
		setTech(project?.tech || "");
		setDeadline(project?.deadline || "");
		setRole(project?.role || "");
		setCountry(project?.country || "");
		setCity(project?.city || "");
		setContract(project?.contract || "Part-time");
		setMinWage(project?.minWage || 0);
		setMaxWage(project?.maxWage || 0);
		setMethod(project?.method || "Hybrid");
		setFileContent(null);
		window.scrollTo(0,0);
	}, [project]);
	

	const handleInputChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setStateFn(e.target.value);
	};

	const handleTextareaChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setStateFn(e.target.value);
	};

	const handleSelectChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLSelectElement>) => {
		setStateFn(e.target.value);
	};
	
	function handleSave() {
		let error = document.getElementById('edit-error-msg') as HTMLParagraphElement;
		const today = new Date();
		today.setDate(today.getDate() + 10);
		const todayFormatted = today.toISOString().split('T')[0];
	
		if (
			projectName === "" || 
			projectDescr === "" || 
			area === "" || 
			techn === "" || 
			projectDeadline === "" || 
			role === "" || 
			country === "" || 
			city === "" ||
			contract === "" || 
			method === ""
		) {
			error.innerText = "Please fill all the required camps.";
			return ;
		}
		if (projectDescr.length < 200) {
			error.innerText = "Description must be at lest 200 characters long.";
			return ;
		}
		if (projectDeadline < todayFormatted) {
			error.innerHTML = "Deadline must be at lest 10 days from now.";
			return ;
		}
		if (minWage <= 0 || 
			maxWage <= 0) {
			error.innerText = "Invalid wage values.";
			return ;
		}

		updateProject(
			{
				type,
				projectName,
				projectDescr,
				area,
				techn,
				projectDeadline,
				role,
				country,
				city,
				contract,
				minWage,
				maxWage,
				method
			},
			(response:any) => {
				// Handle success
				setPrompt({
					message: "Project updated successfully!",
					load: true,
					action: "alert",
				});
				window.history.back();
			},
			(error:any) => {
				// Handle error
				error.innerText = "Error updating project.";
			}
		);
		window.location.href ='/company/projects/';
		
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files && event.target.files[0];
		if (file && file.name.endsWith('.pdf')) {
		  	setFileContent(file);
		} else {
			alert("Please upload a valid .pdf file");
		}
	  };

	  const openFileDialog = () => {
		const fileInput = document.getElementById("upload-file-input");
		fileInput?.click();
	  };

	function handleCancel() {
		setPrompt({
			message: "Are you sure you want to discard all changes?",
			load: true,
			action: "confirm"
		});
	}

	// Get Country and Cities
	const countryData = Country.getAllCountries();

	const [countries, setCountries] = useState(countryData);
	const [countrySelected, setCountrySelected] = useState(countryData[0]);
	const [cities, setCities] = useState(State.getStatesOfCountry(countrySelected?.isoCode));

	const handleCountrySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedCountry = countries.find(country => country.name === e.target.value);
	
		if (selectedCountry) {
			setCountrySelected(selectedCountry);
			setCountry(selectedCountry.name);
			const newCities = State.getStatesOfCountry(selectedCountry.isoCode);
			setCities(newCities);
		}
	};
	
	// Sort countries
	useEffect(() => {
		const sortedCountries = [...countryData].sort((a, b) => a.name.localeCompare(b.name));
		setCountries(sortedCountries);
	}, [countryData]);

	if (!project) return <div className="h-screen flex items-center justify-center">${projectId}</div>;

	return (
		<div className="projects-page relative flex flex-col justify-start items-start w-full">
			<HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null}/>
			<div className="relative flex w-full gap-10 px-5">
				<div className="relative w-full flex flex-col">
					<div className="relative flex w-full justify-between my-8">
						<h3 className="relative text-h4">Edit project</h3>
					</div>
					<div className="relative flex flex-col mb-5">
						<div id="edit-project-project-info" className="relative flex w-full gap-8">
							<div className="relative flex flex-col w-1/2 gap-5">
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2 ml-4">Project name:</h4>
									<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={projectName} placeholder="|" onChange={e => handleInputChange(setProjectName)(e)}/>
								</div>
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2 ml-4">Description:</h4>
									<textarea id="edit-project-desc" className="relative items-start bg-[var(--inputbg)] outline-0 px-4 py-2 w-full h-[20rem] rounded-[18px] border-2 border-blue text-p text-white resize-none" value={projectDescr} placeholder="|" onChange={e => handleTextareaChange(setProjectDescr)(e)}/>
								</div>
							</div>
							<div className="relative flex flex-col w-1/2 gap-5">
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2">Subject:</h4>
									<div className="flex gap-4">
										<button
											id="upload-file-fake-input"
											className="relative bg-blue  w-[7.5rem] py-[3px]  rounded-full text-white"
											onClick={openFileDialog} // trigger the file dialog open
										>
											upload
										</button>
										<input
											id="upload-file-input"
											className="hidden"
											type="file"
											placeholder="upload"
											onChange={handleFileChange} // set up the change event handler
										/>
										<button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">AI generate</button>
									</div>
									{fileContent && <a href={fileContent.name} className="text-white text-left text-[.7rem] mt-4 hover:underline cursor-pointer">{fileContent.name}</a>}
								</div>
								{project.type === "Vacancy" ? null :
									<div className="edit-project-input relative flex flex-col">
										<h4 className="text-h5 text-left w-full mb-2">Project type:</h4>
										<div className="relative flex">
											<div className="relative flex items-center gap-4">
												<p>Vacancy</p>
												<input id="edit-project-type-vacancy" checked={type === "Vacancy"} type="checkbox" className="filters-type rounded-full bg-[#ddd] cursor-pointer" onChange={() => setType("Vacancy")}/>
											</div>
											<div className="relative flex items-center ml-7  gap-4">
												<p>Training</p>
												<input id="edit-project-type-training" checked={type === "Training"} type="checkbox" className="filters-type rounded-full bg-[#ddd] cursor-pointer" onChange={() => setType("Training")}/>
											</div>
										</div>
									</div>
								}
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2 ml-4">Area:</h4>
									<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={area} placeholder="|" onChange={e => handleInputChange(setArea)(e)}/>
								</div>
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2 ml-4">Technology:</h4>
									<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={techn} placeholder="|" onChange={e => handleInputChange(setTech)(e)}/>
								</div>
								<div className="edit-project-input relative flex flex-col">
									<h4 className="text-h5 text-left w-full mb-2 ml-4">Deadline:</h4>
									<input type="date" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={projectDeadline} placeholder="|" onChange={e => handleInputChange(setDeadline)(e)}/>
								</div>
							</div>
						</div>
						{type === "Vacancy" ?
							<div className={"relative flex flex-col w-full gap-5 my-10 border-t-2 border-white pt-10 "}>
								<h2 className="relative text-h4 w-full text-left">Edit vacancy</h2>
								<div id="edit-project-vacancy-info" className="relative flex w-full gap-5">
									<div className="relative flex flex-col w-1/2 gap-5">
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2 ml-4">Job role:</h4>
											<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={role} placeholder="|" onChange={e => handleInputChange(setRole)(e)}/>
										</div>
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2 ml-4">Contract type:</h4>
											<select value={contract} onChange={e => handleSelectChange(setContract)(e)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
												<option className="relative bg-blue" value="Full-time">Full-time</option>
												<option className="relative bg-blue" value="Part-time">Part-time</option>
												<option className="relative bg-blue" value="Porject-only">Project-only</option>
											</select>
										</div>
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2 ml-4">Work method:</h4>
											<select value={method} onChange={e => handleSelectChange(setMethod)(e)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
												<option className="relative bg-blue" value="Hybrid">Hybrid</option>
												<option className="relative bg-blue" value="Remote">Remote</option>
												<option className="relative bg-blue" value="In-Person-Work">In-Person-Work</option>
											</select>
										</div>
									</div>
									<div className="relative flex flex-col w-1/2 gap-5">
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2 ml-4">Wage range:</h4>
											<div className="relative flex gap-5 items-center">
												<input type="number" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={minWage} placeholder="min" min="0" onChange={e => handleInputChange(setMinWage)(e)} />
												-
												<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" value={maxWage} placeholder="max" min="0" onChange={e => handleInputChange(setMaxWage)(e)}/>
											</div>
										</div>
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2 ml-4">Office Location:</h4>
											<div className="relative flex gap-5 items-center">
												<select
													value={country}
													defaultValue={"Country"}
													onChange={(e) => handleCountrySelect(e)}
													className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white"
												>
													<option disabled>Country</option>
													{countries.map(country => (
													<option key={country.isoCode} value={country.name} style={{ backgroundColor: 'var(--blue)' }}>
														{country.name}
													</option>
													))}
												</select>
												<select
													value={city}
													defaultValue={"City"}
													onChange={(e) => handleSelectChange(setCity)(e)}
													className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white"
												>
													<option disabled>City</option>
													{cities.map(city => (
													<option key={city.name} value={city.name} style={{ backgroundColor: 'var(--blue)' }}>
														{city.name}
													</option>
													))}
												</select>
											</div>
										</div>
										<div className="edit-project-input relative flex flex-col">
											<h4 className="text-h5 text-left w-full mb-2">Resources:</h4>
											<button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">upload pdf</button>
										</div>
									</div>
								</div>
							</div>
							: null
						}
		  				<p id="edit-error-msg" className="text-center w-full text-red my-8"></p>
						<div id="edit-project-btns" className="w-full flex justify-center items-center gap-4">
							<button onClick={handleCancel} className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-white">Cancel</button>
							<button onClick={handleSave} className="w-[8rem] h-[2rem] bg-green rounded-full text-white">Save</button>
						</div>
					</div>
				</div>
			</div>
			<Prompt onDialog={question} opened={prompt.load} message={prompt.message} type={prompt.action}/>
		</div>
	);
}

export default EditProject;
