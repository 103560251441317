import React, { useState } from 'react';

import HeaderTop from '../../components/HeaderTop/header-top';

import Section from './section';
import Toggle from '../../components/Toggle/Toggle';

function Main() {
  let [section, setSection] = useState(1);
  const [search, setSearch] = useState("");

  return (
    <div className="courses-page relative flex flex-col justify-start items-start w-full mr-4 mb-4 min-h-screen">
      <HeaderTop filters={false} searchBar={true} page={"achievements"} search={search} setSearch={setSearch} />
      <div className="courses-list relative w-full flex flex-col justify-center items-center gap-8 mt-4">
        <Toggle section={section} changeSection={setSection} first="Achievements" second="Certificates" />
        <Section section={section} search={search} />
      </div>
    </div>
  );
}

export default Main;
