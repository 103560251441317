import React, { useState } from "react";

import HeaderTop from '../../components/HeaderTop/header-top';
import Header from './header/header';
import Section from './section';
import { FilterProvider } from "../../components/Context/projectFilterContext";
import Toggle from "../../components/Toggle/Toggle";

function Projects() {
	const [section, setSection] = useState(1);
	const [search, setSearch] = useState('');

	return (
		<div id='projects' className='relative flex flex-col w-full mr-4 mb-4 min-h-screen'>
			<HeaderTop filters={false} searchBar={true} page={"projects"} search={search} setSearch={setSearch} />
			<Header />
			<div id="projects-header-navbar" className="relative flex justify-center items-center pt-4">
				<Toggle section={section} changeSection={setSection} first="Current" second="Done" />
			</div>
			<div className="projects-page relative flex flex-col justify-center items-start w-full mt-8">
        		<div className="projects-list relative flex">
				<FilterProvider>
					<Section section={section} search={search} />
				</FilterProvider>
				</div>
			</div>
		</div>
	);
}

export default Projects;
