import {GiAchievement} from '@react-icons/all-files/gi/GiAchievement';

export default function AchievementCard({ name, desc, check } : {name:string, desc:string, check:boolean}) {
    let className="ach-card card relative p-5 ";
    if (!check) {
      className += " not-done";
    }
    return (
      <div className={className}>
        <div className="ach-card-top relative flex w-full h-3/5 justify-start items-start">
          <GiAchievement color={`${!check ? "grey" : "var(--yellow)"}`} className="w-12 h-12 mr-5"/>
          <h3 className="text-h3 text-left"><b>{name}</b></h3>
        </div>
        <div className="ach-card-bottom relative w-full h-2/5">
          <h4 className="text-h4 text-left">{desc}</h4>
        </div>
      </div>
    );
  }