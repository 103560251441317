import { useState } from "react";

import HeaderTop from "../../components/HeaderTop/header-top";
import { FilterProvider } from "../../components/Context/projectFilterContext";

import Section from './section';
import Toggle from "../../components/Toggle/Toggle";

function Jobs() {
	let [section, setSection] = useState(1);
	const [search, setSearch] = useState('');

	return (
		<div id='jobs' className='relative flex flex-col w-full mr-4 mb-4 min-h-screen'>
			<HeaderTop filters={true} searchBar={true} page={"jobs"} search={search} setSearch={setSearch} />
			<div id="company-jobs-header-navbar" className="relative flex justify-center items-center">
                <Toggle section={section} changeSection={setSection} first="Open" second="Closed" />
			</div>
			<div className="jobs-page relative flex flex-col justify-center items-start w-full mt-5">
				<div className="projects-list relative flex ">
					<FilterProvider>
						<Section section={section} search={search} />
					</FilterProvider>
				</div>
			</div>
		</div>
	);
}

export default Jobs;
