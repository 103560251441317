import Courses from '../../jsons/courses.json';

import './projects.css';

function CoursesCard({ ...props }) {
    return (
        <div className="courses-card card relative flex flex-col cursor-pointer">
            <div className="courses-card-top relative flex h-3/5">
                <img src={props.img} alt="img" className="relative w-full" />
            </div>
            <div className="courses-card-bottom relative flex flex-col justify-between h-2/5 px-5 py-2">
                <p className="text-p text-left">{props.about}</p>
                <div className="relative flex justify-between items-center ">
                    <h5 className="text-h5"><b>{props.company}</b></h5>
                    <div className="relative flex flex-col">
                        <h5 className="text-h5 text-right"><b>{props.participants}</b> px.</h5>
                    </div>
                </div>
            </div>
        </div>
    );
  }

function RecommendedCourses() {
    const courses = Courses;
    return (
        <div id="recommended-courses">
            <div className="project-card-line mb-10"></div>
            <div id="project-page-courses" className="relative flex flex-col mb-4 w-full pl-10">
                <h1 className="relative text-h3 text-left mb-10 w-full">Recommended courses</h1>
                <div id="project-page-courses-list" className="relative flex gap-[1rem]">
                    {
                        courses?.courses.filter(course => course.recommended).map((course) => {
                            return (
                                <CoursesCard 
                                    key={course?.id}
                                    company={course?.company || "Loading..."} 
                                    about={course?.description || "Loading..."}
                                    img={course?.imagePath || "..."} 
                                    participants={course?.peopleDoing || "..."}/>
                            );	

                        })
                    }
                </div>
                <div className='mt-10 flex w-full'>
                    <button className='px-6 py-1 bg-blue rounded-full' onClick={() => window.location.replace('/agent/courses')}>explore more</button>
                </div>
            </div>
        </div>
    );
}

export default RecommendedCourses;