import React from "react";
import './achievements.css';

import AchievData from '../../jsons/achiev.json';
import AchievementCard from "./AchievCard";

function Section({section, search}:{section:number, search:string}) {
	let achievs = AchievData.achievements;

    // Filter based on search
    achievs = achievs.filter(p => p.description.toLowerCase().includes(search.toLowerCase()));

    if (section === 2) {
        return (
            <>
                <p>Coming soon!</p>
            </>
        );
    } else {
        return (
            <div className="achievements-page relative flex flex-col justify-start items-start w-full ">
                <div className="achievements-list relative grid">
                    {achievs.filter(achiev => achiev.active).map(achiev => (
                        <AchievementCard key={achiev.name} name={achiev.name} desc={achiev.description} check={achiev.active} />
                    ))}
                    {achievs.filter(achiev => !achiev.active).map(achiev => (
                        <AchievementCard key={achiev.name} name={achiev.name} desc={achiev.description} check={achiev.active} />
                    ))}
                </div>
		    </div>
        );
    }
    
}

export default Section;
