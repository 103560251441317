import React from 'react';
import './cards.css';

import { useUserData } from '../../../components/Context/agentContext';
import { Circle } from '../../../components/CircleProgressBar/circle';
import Calendar from '../../../components/Calendar/calendar';

import { GiAchievement } from '@react-icons/all-files/gi/GiAchievement';
import { BsLightningFill } from '@react-icons/all-files/bs/BsLightningFill';

// import Cookies from 'js-cookie';
// import { getUser } from '../../../services/users';

// TODO: fix this
// const savedUserData = Cookies.get('userData');
// const userData = savedUserData ? JSON.parse(savedUserData) : null;
// const user = getUser(userData.id);

function ItSkill({ skill, knowledge }: { skill: string, knowledge: string }) {
	return (
		<div className="relative flex justify-center items-center w-full mb-5">
			<h5 className="text-h5 mr-5  w-1/3">{skill}</h5>
			<div id="skills-card-progress-bg" className="relative w-1/2 bg-[var(--stats-bg)] h-4 rounded-full">
				<div style={{ width: `${knowledge}%` }} className={`absolute h-4 rounded-full it-skill-line bg-[var(--stats)]`}></div>
			</div>
		</div>
	);
}

function SkillsCard() {
	const { user } = useUserData();
	return (
		<div id="card-skills" className="card p-5">
			<h4 className="text-h4 text-center mb-10">Skills</h4>
			{
				user?.skills.length === 0 ?
					<div className='relative w-full flex justify-center items-center'>
						<p className='text-[var(--soft-text)]'>Do projects to earn new skills</p>
					</div>
					:
					<div className="relative flex w-full text-left">
						<div className="relative flex flex-col w-1/2 text-left">
							{
								user?.skills.slice(0, user.skills.length / 2).map((skill: any) => {
									return <ItSkill key={skill.name} skill={skill.name} knowledge={skill.value} />
								})
							}
						</div>
						<div className="relative flex flex-col w-1/2 text-left">
							{
								user?.skills.slice(user.skills.length / 2, user.skills.length).map((skill: any) => {
									return <ItSkill key={skill.name} skill={skill.name} knowledge={skill.value} />
								})
							}
						</div>
					</div>
			}
		</div>
	);
}

function Achievements({ achievements }: { achievements: string }) {
	return (
		<div className="relative flex justify-start items-center w-full text-left mb-9">
			<GiAchievement color='var(--yellow)' className='w-6 h-6 mr-5' />
			<h5 className="text-h5">{achievements}</h5>
		</div>
	);
}

function AchievementsCard() {
	const { user } = useUserData();
	return (
		<div id="card-achievements" className="card p-5">
			<h4 className="text-h4 text-center mb-10">Achievements</h4>
			{
				user?.achievements.length === 0 ?
					<div className='relative w-full flex justify-center items-center'>
						<p className='text-[var(--soft-text)]'>You don't have any achievement</p>
					</div>
					:
					<div className="relative flex flex-col w-full text-left pl-10">
						{user?.achievements.map((achiev:string, index:number) => {
							return <Achievements key={index} achievements={achiev} />
						})
						}
					</div>
			}
		</div>
	);
}


function Project({ ...props }) {
	return (
		<div onClick={() => window.location.replace(`/agent/projects/${props.id}/`)} className="done-project relative flex flex-col justify-center items-center cursor-pointer">
			<div className="done-project-top relative w-full flex flex-col justify-start items-start p-4">
				<div className="relative h-1/2">
					<h4 className="text-h5 text-left"><b>{props.name}</b></h4>
					<h5 className="text-h5 text-left">{props.company}</h5>
				</div>
				<div className="relative w-full flex justify-center items-center h-1/2">
					<BsLightningFill color='white' className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6' />
				</div>
			</div>
			<div className="home-project-card-line"></div>
			<div className="done-project-bottom relative w-full flex justify-center items-center p-3">
				<h5 className="done-project-eval text-h5 text-white ">{props.grade} / 100</h5>
			</div>
		</div>
	);
}

function DoneProjectsCard() {
	const { user } = useUserData();
	return (
		<div id="card-done-projects" className="card p-5 relative flex flex-col justify-between">
			<h4 className="text-h4 text-left mb-4 ml-6">Done Projects</h4>
			<div id="card-done-projects-list" className="relative text-white px-5 pb-5">
				{user?.projects.map((project: any) => {
					return <Project key={project?.id || 0}
						id={project?.id || 1}
						username={user?.username}
						name={project?.name || "Loading..."}
						company={project?.company || "Loading..."}
						grade={project?.grade || "..."}
					/>
				})
				}
				<div id="more-projects-card" className=" relative flex flex-col justify-between py-4 px-2 items-center text-[var(--text)]">
					<div>
						<h1>Want more?</h1>
						<p className="text-[.7rem]">Search for new projects</p>
					</div>
					<BsLightningFill color='var(--text)' className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6' />
					<button id="button-more-projects" className="button-project px-10" onClick={() => window.location.replace('/agent/projects')}>More</button>
				</div>
			</div>
		</div>
	);
}

function EvalItem({ ...props }) {
	let active = props.deadline <= 1 ? "close-deadline" : "";
	return (
		<div onClick={() => window.location.replace('/agent/projects/projectName/other-username/eval')} className={"eval-item relative flex justify-between items-center w-full h-[4rem] text-left px-4 rounded-[18px] mb-5 cursor-pointer " + active}>
			<div className="relative flex flex-col">
				<h3 className="text-h4">{props.name}</h3>
				<h3 className="text-h5">from {props.user}</h3>
			</div>
			{props.deadline <= 1 ? <h2 className='relative'><span>1</span> day left</h2> : null}
			<div className="eval-item-arrow relative flex flex-col justify-between items-center">
				<h1>→</h1>
			</div>
		</div>
	);
}

function Evaluations() {
	const { user } = useUserData();
	return (
		<div id="evaluations-card" className={`relative flex card flex-col justify-start items-center px-7 pt-5`}>
			<h4 className="text-h4 text-right w-full mb-6">Evaluate others</h4>
			{
				user?.evaluations.length === 0 ?
					<div className='relative w-full flex justify-center items-center'>
						<p className='text-[var(--soft-text)]'>You haven't any evaluation</p>
					</div>
					:
					<div id="evaluations-list" className="relative flex flex-col w-full">
						{
							user?.evaluations.map((evalu: any) => {
								return <EvalItem key={evalu.id} name={evalu.projectName} user={evalu.user} deadline={evalu.deadline} />
							})
						}
					</div>
			}
		</div>
	);
}

function StatsCard() {
	const { user } = useUserData();
	return (
		<div id="card-stats" className="relative card p-5">
			<h1 className="mb-10 text-h4">User Stats</h1>
			<Circle level={user?.level || 0} nextLevel={user?.nextLevel || 0} width={200} complete={user?.nextLevel || 0} />
		</div>
	);
}

function CalendarCard() {
	const { user } = useUserData();
	return (
		<div id="card-calendar" className="card relative flex flex-col w-full justify-start items-start overflow-y-scroll p-4">
			<h1 className='w-full text-left text-h4 mb-4'>Calendar</h1>
			<div className='flex flex-col gap-4 justify-between w-full px-'>
				<div className='w-full text-left flex gap-4'>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-red'></div>Deadlines</div>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-[var(--yellow)]'></div>End fase 1</div>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-blue'></div>End fase 2</div>
				</div>
				<Calendar eventsList={user?.events || []} />
			</div>
		</div>
	);
}

export {
	CalendarCard,
	AchievementsCard,
	StatsCard,
	SkillsCard,
	Evaluations,
	DoneProjectsCard
};
