import React, { useEffect, useState } from "react";

import { Country, State } from 'country-state-city';

import HeaderTop from "../../components/HeaderTop/header-top";
import Prompt from "../../components/Prompt/prompt";

function CreateProject() {

    const [prompt, setPrompt] = useState({
        message: "",
        load: false,
        action: "",
    });

    const question = (answer: boolean) => {
        if (answer && prompt.action === "confirm") {
            window.location.href = '/company/projects/';
        } else if (prompt.action === 'alert') {
            window.location.href = '/company/jobs/';
        }
        setPrompt({
            message: "",
            load: false,
            action: ""
        });
    }

    const [type, setType] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectDescr, setProjectDescr] = useState("");
    const [area, setArea] = useState("");
    const [techn, setTech] = useState("");
    const [projectDeadline, setDeadline] = useState("");
    const [role, setRole] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [contract, setContract] = useState("Part-time");
    const [minWage, setMinWage] = useState(0);
    const [maxWage, setMaxWage] = useState(0);
    const [method, setMethod] = useState("Hybrid");
    const [fileContent, setFileContent] = useState<File | null>(null);

    const handleInputChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateFn(e.target.value);
    };

    const handleTextareaChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setStateFn(e.target.value);
    };

    const handleSelectChange = (setStateFn: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStateFn(e.target.value);
    };

    function checkProject() {
        const today = new Date();
        today.setDate(today.getDate() + 10);
        const todayFormatted = today.toISOString().split('T')[0];

        if (
            projectName === "" ||
            projectDescr === "" ||
            area === "" ||
            techn === "" ||
            projectDeadline === "" ||
            !fileContent
        ) 
           return "Please fill all the required camps for the project.";
        if (projectDescr.length < 200) 
           return "Description must be at lest 200 characters long.";
           if (projectDeadline < todayFormatted) 
           return "Deadline must be at lest 10 days from now.";
        return "";
    }

    function checkVacancy() {
        if (
            role === "" ||
            country === "" ||
            city === "" ||
            contract === "" ||
            method === ""
        )
           return "Please fill all the required camps for the vacancy.";
        if (minWage <= 0 ||
            maxWage <= 0) 
           return "Invalid wage values.";
        return "";
    }

    function handleSave() {
        let error = document.getElementById('edit-error-msg') as HTMLParagraphElement;
        
        if (type === "Training")
           error.innerHTML = checkProject();
        else if (type === "Vacancy")
           error.innerHTML = checkVacancy();
        else {
            error.innerHTML = checkProject();
            if (error.innerHTML === "") error.innerHTML = checkVacancy();
        }
        if (error.innerText !== "") 
            return ;
        

        // TODO: Create project and/or vacancy
        setPrompt({
            message: `${type === "Both" ? "Vacancy with project" : type} created successfully!`,
            load: true,
            action: "alert"
        });

    }

    function handleCancel() {
        setPrompt({
            message: "Are you sure you want to discard all changes?",
            load: true,
            action: "confirm"
        });
    }

    // Get Country and Cities
    const countryData = Country.getAllCountries();

    const [countries, setCountries] = useState(countryData);
    const [countrySelected, setCountrySelected] = useState(countryData[0]);
    const [cities, setCities] = useState(State.getStatesOfCountry(countrySelected?.isoCode));

    const handleCountrySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = countries.find(country => country.name === e.target.value);

        if (selectedCountry) {
            setCountrySelected(selectedCountry);
            setCountry(selectedCountry.name);
            const newCities = State.getStatesOfCountry(selectedCountry.isoCode);
            setCities(newCities);
        }
    };


    // Sort countries
    useEffect(() => {
        const sortedCountries = [...countryData].sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sortedCountries);
    }, [countryData]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.name.endsWith('.pdf')) {
            setFileContent(file);
        } else {
            setPrompt({
                message: "Please upload a valid pdf file.",
                load: true,
                action: "alert",
            });
        }
    };

    const openFileDialog = () => {
        const fileInput = document.getElementById("upload-file-input");
        fileInput?.click();
    };

    function ChooseType() {
        return (
            <div className="flex justify-center items-center w-full min-h-[80vh]">
                <div className="card w-[25rem] py-14 flex flex-col gap-4">
                    <h2>What do you want to create?</h2>
                    <div className="flex gap-4 justify-center items-center">
                        <button onClick={() => setType("Vacancy")} className="w-[8rem] h-[2rem] bg-blue rounded-full text-white">Vacancy</button>
                        <button onClick={() => setType("Training")} className="w-[8rem] h-[2rem] bg-blue rounded-full text-white">Training</button>
                    </div>
                    <div>
                        <button onClick={() => setType("Both")} className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-black">Both</button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="projects-page relative flex flex-col justify-start items-start w-full min-h-screen">
            <HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null} />
            {
                type === "" ? <ChooseType /> :
                    <div className="relative flex w-full gap-10 px-5">
                        <div className="relative w-full flex flex-col">
                            <div className="relative flex flex-col mb-5 pt-10 justify-between">
                                {type === "Training" || type === "Both" ?
                                    <div>
                                        <h3 className="relative text-h4 text-left mb-5">Create project</h3>
                                        <div id="edit-project-project-info" className="relative flex w-full gap-8">
                                            <div className="relative flex flex-col w-1/2 gap-5">
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Project name:</h4>
                                                    <input value={projectName} type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" onChange={e => handleInputChange(setProjectName)(e)} />
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Description:</h4>
                                                    <textarea value={projectDescr} id="edit-project-desc" className="relative items-start bg-[var(--inputbg)] outline-0 px-4 py-2 w-full h-[20rem] rounded-[18px] border-2 border-blue text-p text-white resize-none" placeholder="|" onChange={e => handleTextareaChange(setProjectDescr)(e)} />
                                                </div>
                                            </div>
                                            <div className="relative flex flex-col w-1/2 gap-5">
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2">Subject:</h4>
                                                    <div className="flex gap-4">
                                                        <button
                                                            id="upload-file-fake-input"
                                                            className="relative bg-blue  w-[7.5rem] py-[3px]  rounded-full text-white"
                                                            onClick={openFileDialog} // trigger the file dialog open
                                                        >
                                                            upload
                                                        </button>
                                                        <input
                                                            id="upload-file-input"
                                                            className="hidden"
                                                            type="file"
                                                            placeholder="upload"
                                                            onChange={handleFileChange} // set up the change event handler
                                                        />
                                                        <button onClick={() => window.open('https://chat.openai.com/g/g-hx3f1DRDF-worklab-project-generator', '_blank')} className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">AI generate</button>
                                                    </div>
                                                    {fileContent && <a href={fileContent.name} className="text-white text-left text-[.7rem] mt-4 hover:underline cursor-pointer">{fileContent.name}</a>}
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Area:</h4>
                                                    <input value={area} type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" onChange={e => handleInputChange(setArea)(e)} />
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Technology:</h4>
                                                    <input value={techn} type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" onChange={e => handleInputChange(setTech)(e)} />
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Deadline:</h4>
                                                    <input value={projectDeadline} type="date" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" onChange={e => handleInputChange(setDeadline)(e)} />
                                                </div>
                                                <div className="edit-project-input relative flex flex-col h-full justify-end">
                                                    <button onClick={type === 'Training' ? () => setType("Both") : () => setType("Training")} className={`relative w-[10rem] py-[3px] rounded-full text-white ${type === 'Training' ? 'bg-blue' : 'bg-red'}`}>{type === 'Training' ? "add vacancy" : "remove vacancy"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {type === "Vacancy" || type === "Both" ?
                                    <div className={`relative flex flex-col w-full gap-5 ${type === "Both" ? 'mt-10' : ""}`}>
                                        <h2 className="relative text-h4 w-full text-left">Create vacancy</h2>
                                        <div id="edit-project-vacancy-info" className="relative flex w-full gap-5">
                                            <div className="relative flex flex-col w-1/2 gap-5">
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Job role:</h4>
                                                    <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" onChange={e => handleInputChange(setRole)(e)} />
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Contract type:</h4>
                                                    <select onChange={e => handleSelectChange(setContract)(e)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
                                                        <option className="relative bg-blue" value="Hybrid">Full-time</option>
                                                        <option className="relative bg-blue" value="Remote">Part-time</option>
                                                        <option className="relative bg-blue" value="In-Person-Work">Project-only</option>
                                                    </select>
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Work method:</h4>
                                                    <select onChange={e => handleSelectChange(setMethod)(e)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
                                                        <option className="relative bg-blue" value="Hybrid">Hybrid</option>
                                                        <option className="relative bg-blue" value="Remote">Remote</option>
                                                        <option className="relative bg-blue" value="In-Person-Work">In-Person-Work</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="relative flex flex-col w-1/2 gap-5">
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Wage range:</h4>
                                                    <div className="relative flex gap-5 items-center">
                                                        <input type="number" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="min" min="0" onChange={e => handleInputChange(setMinWage)(e)} />
                                                        -
                                                        <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white" placeholder="max" min="0" onChange={e => handleInputChange(setMaxWage)(e)} />
                                                    </div>
                                                </div>
                                                <div className="edit-project-input relative flex flex-col">
                                                    <h4 className="text-h5 text-left w-full mb-2 ml-4">Office Location:</h4>
                                                    <div className="relative flex gap-5 items-center">
                                                        <select
                                                            id="signup-country-select"
                                                            defaultValue={"Country"}
                                                            onChange={(e) => handleCountrySelect(e)}
                                                            className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white"
                                                        >
                                                            <option disabled>Country</option>
                                                            {countries.map(country => (
                                                                <option key={country.isoCode} value={country.name} style={{ backgroundColor: 'var(--blue)' }}>
                                                                    {country.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <select
                                                            id="signup-city-select"
                                                            defaultValue={"City"}
                                                            onChange={(e) => handleSelectChange(setCity)(e)}
                                                            className="relative bg-[var(--inputbg)] outline-0 px-4 py-2 w-full rounded-full border-2 border-blue text-p text-white"
                                                        >
                                                            <option disabled>City</option>
                                                            {cities.map(city => (
                                                                <option key={city.name} value={city.name} style={{ backgroundColor: 'var(--blue)' }}>
                                                                    {city.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="edit-project-input relative flex flex-col h-full justify-end">
                                                    <button onClick={type === 'Vacancy' ? () => setType("Both") : () => setType("Vacancy")} className={`relative w-[10rem] py-[3px] rounded-full text-white ${type === 'Vacancy' ? 'bg-blue' : 'bg-red'}`}>{type === 'Vacancy' ? "add project" : "remove project"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                <p id="edit-error-msg" className="text-center w-full text-red my-8"></p>
                                <div id="edit-project-btns" className="w-full flex justify-center items-center gap-4">
                                    <button onClick={handleCancel} className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-white">Cancel</button>
                                    <button onClick={handleSave} className="w-[8rem] h-[2rem] bg-green rounded-full text-white">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Prompt onDialog={question} opened={prompt.load} message={prompt.message} type={prompt.action} />
        </div>
    );
}

export default CreateProject;
