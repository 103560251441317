
import './profile.css'

import Header from '../../components/Header/agent-header';
import HeaderTop from '../../components/HeaderTop/header-top';
import UserSearch from './userSearch';

import {
	CalendarCard,
	AchievementsCard,
	StatsCard,
	SkillsCard,
	Evaluations,
	DoneProjectsCard
} from './cards/cards';
import { useState } from 'react';

function Profile() {

	const [search, setSearch] = useState('');

	return (
		<div className='min-h-screen w-full'>
			<HeaderTop filters={false} searchBar={true} page={"profile"} search={search} setSearch={setSearch} />
			{
				search !== "" ? <UserSearch search={search}/> :
				<div id='profile' className='relative flex flex-col w-full '>
					<Header />
					<CalendarCard />
					<AchievementsCard />
					<StatsCard />
					<SkillsCard />
					<div id="evaluation-card-container" className='card relative w-full grid-area-[evals] h-[20rem]'>
						<Evaluations />
					</div>
					<DoneProjectsCard />
				</div>
			}
		</div>
	);
}

export default Profile;
