import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

export default function ProjectFilters() {

    const [showFilters, setShowFilters] = useState(false);

    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [projectType, setProjectType] = useState("All");
    const [peopleDoing, setpeopleDoing] = useState("Default");
    const [deadline, setDeadline] = useState("Default");

    const navigate = useNavigate();

    const applyFilters = () => {
      navigate(window.location.pathname + `?projectType=${projectType}&peopleDoing=${peopleDoing}&deadline=${deadline}`);
    };

    function FiltersMenu() {

        return (
            <div id="filters-menu" className="invert-card absolute top-[4rem] left-[0rem] w-[18rem] h-[16rem] bg-white z-[5] p-5 flex-col">
                <div className="relative flex flex-col">
                    <h4 className="text-h5 text-left w-full mb-2">Project type:</h4>
                    <div className="relative flex gap-4">
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">All</p>
                            <input id="filters-type-all" checked={projectType === "All"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setProjectType("All")}/>
                        </div>
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">Vacancy</p>
                            <input id="filters-type-vacancy" checked={projectType === "Vacancy"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setProjectType("Vacancy")}/>
                        </div>
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">Training</p>
                            <input id="filters-type-training" checked={projectType === "Training"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setProjectType("Training")}/>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col mt-4">
                    <h4 className="text-h5 text-left w-full mb-2">People doing:</h4>
                    <div className="relative flex gap-4">
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">Default</p>
                            <input id="filters-people-default" checked={peopleDoing === "Default"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setpeopleDoing("Default")}/>
                        </div>
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">A lot</p>
                            <input id="filters-people-more" checked={peopleDoing === "more"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setpeopleDoing("more")}/>
                        </div>
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">A few</p>
                            <input id="filters-people-less" checked={peopleDoing === "less"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setpeopleDoing("less")}/>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col mt-4">
                    <h4 className="text-h5 text-left w-full mb-2">Deadline:</h4>
                    <div className="relative flex gap-4">
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">Default</p>
                            <input id="filters-deadline-default" checked={deadline === "Default"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("Default")}/>
                        </div>
                        <div className="relative flex items-center gap-2">
                            <p className="text-h5">&gt; 1 month</p>
                            <input id="filters-deadline-more" checked={deadline === "more"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("more")}/>
                        </div>
                    </div>
                </div>
                <button onClick={applyFilters} className='bg-blue w-[5rem] h-[1.5rem] text-[.8rem] mt-4 flex items-center justify-center rounded-full'>apply</button>
            </div>
        );
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current && 
                menuRef.current.contains(event.target as Node) && 
                buttonRef.current && 
                buttonRef.current.contains(event.target as Node)
            ) {
                setShowFilters(false);
            }
        };
        

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button id="header-filters" onClick={() => setShowFilters(!showFilters)} ref={buttonRef} className="card relative flex justify-center items-center mr-4 miletres:hidden">
                <div className="mr-2 flex flex-col justify-between items-center h-[.7rem]">
                    <div className="w-[15px] bg-white h-[1.5px]"></div>
                    <div className="w-[10px] bg-white h-[1.5px] "></div>
                    <div className="w-[5px] bg-white h-[1.5px] "></div>
                </div>
                <p>Filters</p>
            </button>
            {showFilters && <div ref={menuRef}><FiltersMenu /></div>}
        </>
    );
}

