import axios from "axios";

const URL = process.env.URL+"/Users"

const GetUser = (id) => {
    axios
        .get(URL + `/${id}`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful get User response here
            // To Project page
            /**
             * {
             *   "id": 1,
             *   "UserName": "Example User",
             *   "description": "We specialize in software development",
             *   "projectsCreated": 5,
             *   "plan": "NORMAL"
             * }
             */
        })
        .catch((error) => {
            console.error("Get User failed:", error);
            // Handle login error here
        });
};

const GetUsers = (limit, offset) => {
    axios
        .get(URL, {params: {limit, offset}})
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful get Users response here
            // To Project page
            /**
             * [
             *   {
             *     "id": 1,
             *     "UserName": "Example User",
             *     "description": "We specialize in software development",
             *     "projectsCreated": 5,
             *     "plan": "NORMAL"
             *   }
             * ]
             */
        })
        .catch((error) => {
            console.error("Get Users failed:", error);
            // Handle login error here
        });
};

const DeleteUser = (id) => {
    axios
        .delete(URL + `/${id}`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful delete User response here
            // To Project page
            /**
             * If success, return 204
             */
        })
        .catch((error) => {
            console.error("Delete User failed:", error);
            // Handle login error here
        });
};

const CreateUser = (UserName, description, plan, user_id) => {
    axios
        .post(URL, { UserName, description, plan, user_id })
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful create User response here
            // To Project page
            /**
             * If success, return 201
             */
        })
        .catch((error) => {
            console.error("Create User failed:", error);
            // Handle login error here
        });
};

const UpdateUser = (
    { firstName, lastName, username, email, country, city, birthday },
    onSuccess,
    onError
  ) => {
    axios
      .post(URL, { firstName, lastName, username, email, country, city, birthday })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          onSuccess(res);
        } else {
          onError(res);
        }
      })
      .catch((error) => {
        console.error("Update User failed:", error);
        onError(error);
      });
  };

const UpdateUserPassword = (
    { newPass, confirmNewPass },
    onSuccess,
    onError
    ) => {
    axios
        .post(URL, { newPass, confirmNewPass })
        .then((res) => {
        console.log(res);
        if (res.status === 201) {
            onSuccess(res);
        } else {
            onError(res);
        }
        })
        .catch((error) => {
        console.error("Update User failed:", error);
        onError(error);
        });
};
  
export const getUser = GetUser;
export const getUsers = GetUsers;
export const deleteUser = DeleteUser;
export const createUser = CreateUser;
export const updateUser = UpdateUser;
export const updateUserPassword = UpdateUserPassword;