import React, { createContext, useContext, useState } from 'react';

interface FilterContextType {
    projectType: string;
    setProjectType: (type: string) => void;
    peopleDoing: string;
    setPeopleDoing: (people: string) => void;
    deadline: string;
    setDeadline: (deadline: string) => void;
}

const defaultFilterValues: FilterContextType = {
    projectType: 'All',
    setProjectType: () => {},
    peopleDoing: 'Default',
    setPeopleDoing: () => {},
    deadline: 'Default',
    setDeadline: () => {}
};

export const FilterContext = createContext<FilterContextType>(defaultFilterValues);

export const useFilter = () => useContext(FilterContext);

interface FilterProviderProps {
    children: React.ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
    const [projectType, setProjectType] = useState('All');
    const [peopleDoing, setPeopleDoing] = useState('Default');
    const [deadline, setDeadline] = useState('Default');

    return (
        <FilterContext.Provider value={{ projectType, setProjectType, peopleDoing, setPeopleDoing, deadline, setDeadline }}>
            {children}
        </FilterContext.Provider>
    );
}
