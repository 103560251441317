import React, { useState } from "react";
import '../settings.css';

import Prompt from "../../../components/Prompt/prompt";
import {FiPaperclip} from '@react-icons/all-files/fi/FiPaperclip';
import { useUserData } from '../../../components/Context/agentContext';

function CV() {

	const [fileContent, setFileContent] = useState<File | null>(null);

	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files && event.target.files[0];
		if (file && file.name.endsWith('.pdf')) {
		  	setFileContent(file);
		} else {
			setPrompt({
				message: "Please upload a valid pdf file.",
				load: true,
				action: "alert",
			});
		}
	  };

	  const openFileDialog = () => {
		const fileInput = document.getElementById("upload-file-input");
		fileInput?.click();
	  };

	  const analizeCv = () => {
		if (!fileContent) {
			setPrompt({
				message: "Please upload your CV first.",
				load: true,
				action: "alert",
			});
		}
		// TODO: analize CV
	  }

	  function ItSkill({ skill, knowledge }: { skill: string, knowledge: string }) {
		return (
			<div className="relative flex justify-center items-center w-full mb-5">
				<h5 className="text-h5 mr-5  w-1/3">{skill}</h5>
				<div id="skills-card-progress-bg" className="relative w-1/2 bg-[var(--stats-bg)] h-4 rounded-full">
					<div style={{ width: `${knowledge}%` }} className={`absolute h-4 rounded-full it-skill-line bg-[var(--stats)]`}></div>
				</div>
			</div>
		);
	}
	
	function SkillsCard() {
		const { user } = useUserData();
		return (
			<div id="cv-settings-skills"  className="card p-5 w-[20rem] h-[18rem] overflow-y-scroll">
				<h4 className="text-h4 text-center mb-10 font-bold">Skills</h4>
				{
					user?.skills.length === 0 ?
					<div className='relative w-full flex justify-center items-center'>
						<p className='text-[var(--soft-text)]'>Do projects to earn new skills</p>
					</div>
					:
					<div className="relative flex flex-col w-full text-left">
						<div className="relative flex flex-col w-full text-left">
							{
								user?.skills.slice(0, user.skills.length / 2).map((skill: any) => {
									return <ItSkill key={skill.name} skill={skill.name} knowledge={skill.value} />
								})
							}
						</div>
						<div className="relative flex flex-col w-full text-left">
							{
								user?.skills.slice(user.skills.length / 2,user.skills.length).map((skill: any) => {
									return <ItSkill key={skill.name} skill={skill.name} knowledge={skill.value} />
								})
							}
						</div>
					</div>
				}
			</div>
		);
	}

  return (
    <div id="cv-settings" className="settings-section relative flex flex-col w-full h-full">
      	<div className="relative flex flex-col w-full h-full">
			<h1 className="text-h3 text-left mb-8">Your CV</h1>
			<div className="settings-cv-info relative flex text-left w-full mb-10 gap-8">
				<div className="w-full flex flex-col gap-4">
					<p className="text-h5 text-left">Worklab CV</p>
					<div id='header-cv' onClick={openFileDialog} className='card flex gap-4 h-[3rem] w-[12rem] justify-center items-center rounded-full cursor-pointer'>
					<FiPaperclip color='var(--text)' className='w-6 h-6'/>
						<p>download CV</p>
					</div>
					<SkillsCard />
				</div>	
				<div className="w-full flex flex-col gap-4">
					<div className="w-full flex flex-col mr-8 gap-4">
						<p className="text-h5 text-left">Current CV</p>
						<div id='header-cv' onClick={openFileDialog} className='card flex gap-4 h-[3rem] w-[12rem] justify-center items-center rounded-full cursor-pointer'>
						<FiPaperclip color='var(--text)' className='w-6 h-6'/>
							<p>upload CV</p>
						</div>
						<input
							id="upload-file-input"
							className="hidden"
							type="file"
							placeholder="upload"
							onChange={handleFileChange} // set up the change event handler
						/>
						{fileContent && <a href={fileContent.name} className="text-white text-left text-[1rem] hover:underline cursor-pointer">{fileContent.name}</a>}
					</div>
					<div className="flex items-center">
						<p onClick={() => analizeCv()} className="text-h5 text-[var(--yellow)] cursor-pointer">analize CV</p>
					</div>
					<p className="mt-10">Join both your real and Worklab CV here</p>
					<div id="profile-settings-btn" className="w-full flex justify-start mb-4">
						<button className="bg-[var(--yellow)] px-8 py-1 rounded-full text-white">Master CV</button>
					</div>
				</div>
			</div>
      	</div>
		<Prompt message={prompt.message} opened={prompt.load} onDialog={() => setPrompt({message: "", load: false, action: ""})} type="alert" />
    </div>
  );
}

export default CV;
