import Header from '../../components/Header/company-header';
import HeaderTop from '../../components/HeaderTop/header-top';
import UserSearch from './userSearch';

import './profile.css';

import {
	StatsCard,
	HistoryProjectsCard,
	VacancyCard
} from './cards/cards';
import { useState } from 'react';

function Home() {
	const [search, setSearch] = useState('');
	return (
		<>
			<HeaderTop filters={false} searchBar={true} page={""} search={search} setSearch={setSearch} />
			{search !== "" ? <UserSearch search={search} /> :
				<div id='company-other-profile' className='relative flex flex-col w-full '>
					<Header />
					<StatsCard />
					<VacancyCard />
					<HistoryProjectsCard />
				</div>
			}
		</>
	);
}

export default Home;
