import React from "react";

import {BsLightningFill} from '@react-icons/all-files/bs/BsLightningFill';
import './circle.css';

export const Circle = ({
  level,
  nextLevel,
  width,
  complete
}: {
  level: number;
  nextLevel: number;
  width:number,
  complete:number
}) => {

    let radius = 85;
    let dashArray = radius * Math.PI * 2;
    let dashOffset = dashArray - (dashArray * complete) / 100;

    return (
        <div className="relative flex justify-center items-center w-full">
            <svg 
                width={width} 
                height={width} 
                viewBox={`0 0 ${width} ${width}`}>
                <circle 
                    cx={width / 2} 
                    cy={width / 2} 
                    strokeWidth="15px" 
                    r={radius}
                    className="circle-background"
                />

                <circle 
                    cx={width / 2} 
                    cy={width / 2} 
                    strokeWidth="15px" 
                    r={radius}
                    className="circle-progress"
                    style={{
                        strokeDasharray: dashArray,
                        strokeDashoffset: dashOffset
                    }}
                    transform={`rotate(-90 ${width / 2} ${width / 2})`}
                />
                <text
                    x="50%"
                    y="50%"
                    dy="0.3rem"
                    textAnchor="middle"
                    className="circle-text">
                    {level}.{nextLevel}%
                </text>
            </svg>
            <BsLightningFill color='var(--stats)' className='absolute top-[65%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6'/>
        </div>
    );
};
