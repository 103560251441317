import React, { useState } from "react";
import membersImg from "../../../content/images/others/profile.jpg";
import '../settings.css';
import { useUserData } from "../../../components/Context/agentContext";
import Prompt from "../../../components/Prompt/prompt";
import { addMemberToCompany } from "../../../services/companies";

// TODO: fix this
// const savedCompanyData = Cookies.get('userData');
// const companyData = savedcompanyData ? JSON.parse(savedCompanyData) : null;
// const company = getcompany(companyData.id);

function SecuritySettings() {
	const { user } = useUserData();
	const [email, setEmail] = useState("");

	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	function isValidEmail(email: string): boolean {
		const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
		return regex.test(email);
	}

	function addMember() {
		let member = document.getElementById('new-member-email') as HTMLInputElement;
		let error = document.getElementById('settings-error-msg') as HTMLParagraphElement;
		if (member.value === "" || !isValidEmail(member.value)) {
			error.innerText = "Make sure you write a valid email.";
			return ;
		}
		addMemberToCompany(
			email,
			(response:any) => {
				// Handle success
				setPrompt({
					message: "Member added successfully!",
					load: true,
					action: "save"
				});
				error.innerText = "";
				member.value = ""; // Reset the member input value
			},
			(errorResponse:any) => {
				// Handle error
				error.innerText = errorResponse.data.message || "Error updating info!";
			}
		);
		
	}

	function removeMember() {
		let ok = window.confirm("Are you sure you want to remove this member?");
		if (!ok) return
		setPrompt({
			message: "Member removed succesfully!",
			load: true,
			action: "save"
		});
	}

	function Member({...props}) {
		return (
		  <div className="member-item relative flex justify-between items-center w-full h-[4rem] text-left px-4 rounded-[18px] mb-4 cursor-pointer ">
			<div className="relative flex items-center">
			  <img src={membersImg} alt="img" className="relative w-8 rounded-full mr-5" />
			  <h3 className="text-h5 mr-10">{props.name}</h3>
			</div>
			<div className="relative flex-flex-col justify-between items-center">
				<button onClick={removeMember} className="bg-blue px-3 py-1 rounded-full text-white text-[.8rem]">remove</button>
			</div>
		  </div>
		);
	  }
	  
	  function Members() {
		return (
		  <div id="settings-member-card" className="card relative flex flex-col justify-start items-center p-8 mb-4">
			<div className="relative flex justify-between w-full mb-10">
			  <h4 className="text-h4 text-center">Members</h4>
			</div>
			<div id="member-list" className="relative flex flex-col w-full">
			{user?.members.map((member:any) => {
          		return (<Member key={member.id} name={member.name} active={member.online}/>);
        	})}
			</div>
		  </div>
		);
	  }
	

  return (
    <div id="members-settings" className="settings-section relative flex flex-col w-full h-full px-10">
      	<div className="relative flex flex-col w-full h-full">
		  <p id="settings-error-msg" className="text-left w-full text-red mb-4"></p>
			<div id="members-settings-btn" className="w-full flex justify-start my-4">
				<input id="new-member-email" type="email" onChange={e => setEmail(e.target.value)} placeholder="| Email" className="mr-10" value={email}/>
				<button onClick={addMember} className="bg-blue px-8 py-1 rounded-full text-white">Add</button>
			</div>
			<Members/>
      	</div>
		<Prompt message={prompt.message} opened={prompt.load} onDialog={() => setPrompt({message: "", load: false, action: ""})} type="alert" />
    </div>
  );
}

export default SecuritySettings;
