import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useUserData } from '../../../components/Context/agentContext';

const getIntroOfPage = (label:string) => {
  if (label === 'Total') {
    return "Number of projects in Worklab";
  }
  if (label === 'Average') {
    return "Average projects done per agent";
  }
  if (label === 'Mine') {
    return "The number of projects I did";
  }
  return '';
};

const CustomTooltip = ({ ...props }) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">{`${props.label} : ${props.payload[0].value}`}</p>
        <p className="intro">{getIntroOfPage(props.label)}</p>
      </div>
    );
  }

  return null;
};

const ProjectChart = () => {
    const user = useUserData();
    let total = 74;
    let average = 27;
    let usersTotal = user?.user?.projects.length || 0;

    let data = [
        {
        name: 'Total',
        uv: 4000,
        amt: 2400,
        Projects: total,
        },
        {
        name: 'Average',
        uv: 3000,
        amt: 2210,
        Projects: average,
        },
        {
        name: 'Mine',
        uv: 2000,
        amt: 2290,
        Projects: usersTotal,
        }
    ]
    return (
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            right: 30,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Projects" barSize={25} fill="var(--stats)" />
        </BarChart>
      </ResponsiveContainer>
    );
};

export default ProjectChart;