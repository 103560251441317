import './style.css'

export default function Calendar({eventsList}: {eventsList: []}) {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth(); // Note: January is 0!

	const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
	const startingDayOfWeek = firstDayOfMonth.getDay(); // Note: Sunday is 0!

	const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

	const calendarDays = [];

	for (let i = 0; i < startingDayOfWeek; i++) {
		calendarDays.push(<div key={`blank-${i}`} className="p-4"></div>);
	}

	const getColorForEvent = (name: string) => {
		switch (name) {
			case 'deadline':
				return 'bg-red';
			case 'end of fase 1':
				return 'bg-[var(--yellow)]';
			case 'end of fase 2':
				return 'bg-blue';
			default:
				return '';
		}
	};

	const events = eventsList.map((event:any) => {
		const [day, month] = event.date.split('/').map(Number);
		return { ...event, day, month };
	});

	for (let i = 1; i <= daysInMonth; i++) {
		const event = events.find((e:any) => e.day === i && e.month === currentMonth + 1);
		const eventColorClass = event ? getColorForEvent(event.name) : '';
		calendarDays.push(
			<div key={i} className={`p-1 text-[.8rem] cinco:text-[.9rem] flex justify-center items-center rounded-full ${eventColorClass} `}>
				{i}
			</div>
		);
	}

	return (
		<div className="flex justify-center items-center flex-col w-full">
			<div className="grid grid-cols-7 gap-2 ">
				{calendarDays}
			</div>
		</div>
	);
}
