import React, { useEffect, useState } from "react";

import { Country, State } from 'country-state-city';
import { registerCompany } from "../../services/authentication.js";

import './signup.css';

export default function CompanyForm() {

    // COMPANY
    const [companyName, setCompanyName] = useState("");
    const [companySector, setCompanySector] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [companyUsername, setCompanyUsername] = useState("");
    const [companyCountry, setCompanyCountry] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [companyPassword, setCompanyPassword] = useState("");
    const [companyConfirmPassword, setCompanyConfirmPassword] = useState("");
    const [companyAcceptTerms, setCompanyAcceptTerms] = useState(false);

    // HANDLERS
    const handleInputChange = (setStateFn: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setStateFn(e.target.value);
    };

    const handleCompanyAcceptTermsChange = (e: any) => {
        setCompanyAcceptTerms(e.target.checked);
    };

    const handleSelectChange =
    (setStateFn: React.Dispatch<React.SetStateAction<string>>) =>
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            setStateFn(e.target.value);
        };

    // COMPANY SIGN UP
    const handleCompanySignup = () => {
        function isValidEmail(email: string): boolean {
            const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return emailPattern.test(email);
        }

        let error = document.getElementById('signup-error-msg') as HTMLParagraphElement;

		const validations = [
			{ check: companyName, message: 'Name is required!' },
			{ check: companySector, message: 'Sector is required!' },
			{ check: companyEmail && isValidEmail(companyEmail), message: 'Enter a valid email address!' },
			{ check: companyUsername, message: 'Username is required!' },
			{ check: companyPassword, message: 'Password is required!' },
			{ check: companyConfirmPassword, message: 'Confirm your password' },
			{ check: companyPassword === companyConfirmPassword, message: 'Passwords do not match' },
			{ check: companyAcceptTerms, message: 'Please read and accept the T&C!' },
		];

		for (const validation of validations) {
			if (!validation.check || validation.check === "") {
				error.innerText = validation.message;
				return;
			}
		}

        registerCompany(companyName, companySector, companyEmail, companyUsername, companyCountry, companyCity, companyPassword, companyConfirmPassword);
        window.location.href = "/company/profile";
    };

    // Get Country and Cities
    const countryData = Country.getAllCountries();

    const [countries, setCountries] = useState(countryData);
    const [countrySelected, setCountrySelected] = useState(countryData[0]);
    const [cities, setCities] = useState(State.getStatesOfCountry(countrySelected?.isoCode));

    const handleCountrySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = countries.find(country => country.name === e.target.value);
    
        if (selectedCountry) {
            setCountrySelected(selectedCountry);
            setCompanyCountry(selectedCountry.name);
            const newCities = State.getStatesOfCountry(selectedCountry.isoCode);
            setCities(newCities);
        }
    };
    

    // Sort countries
    useEffect(() => {
        const sortedCountries = [...countryData].sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sortedCountries);
    }, [countryData]);

    return (
        <>
            <div className="relative flex flex-col w-full">
                <div className="flex">
                    <input
                        type="text"
                        placeholder="Name"
                        className="signup-input w-1/2 mr-5"
                        value={companyName}
                        onChange={e => handleInputChange(setCompanyName)(e)}
                    />
                    <input
                        type="text"
                        placeholder="Sector"
                        className="signup-input w-1/2"
                        value={companySector}
                        onChange={e => handleInputChange(setCompanySector)(e)}
                    />
                </div>
                <div className="flex">
                    <select
                        id="signup-country-select"
                        defaultValue={"Country"}
                        onChange={(e) => handleCountrySelect(e)}
                        className="signup-input w-1/2 mr-5 relative bg-[var(--inputbg)] outline-0 px-4 py-2 border-none text-p text-white cursor-pointer"

                    >
                        <option disabled>Country</option>
                        {countries.map(country => (
                        <option key={country.isoCode} value={country.name} style={{ backgroundColor: 'var(--blue)' }}>
                            {country.name}
                        </option>
                        ))}
                    </select>
                    <select
                        id="signup-city-select"
                        defaultValue={"City"}
                        onChange={(e) => handleSelectChange(setCompanyCity)(e)}
                        className="signup-input w-1/2 relative bg-[var(--inputbg)] outline-0 px-4 py-2 border-none text-p text-white cursor-pointer"
                    >
                        <option disabled>City</option>
                        {cities.map(city => (
                        <option key={city.name} value={city.name} style={{ backgroundColor: 'var(--blue)' }}>
                            {city.name}
                        </option>
                        ))}
                    </select>
                </div>
                <input
                    type="text"
                    placeholder="Email"
                    className="signup-input"
                    value={companyEmail}
                    onChange={e => handleInputChange(setCompanyEmail)(e)}
                />
                <input
                    type="text"
                    placeholder="Username"
                    className="signup-input"
                    value={companyUsername}
                    onChange={e => handleInputChange(setCompanyUsername)(e)}
                />
                <div className="flex">
                    <input
                        type="password"
                        placeholder="Password"
                        className="signup-input w-1/2 mr-5"
                        value={companyPassword}
                        onChange={(e) => handleInputChange(setCompanyPassword)(e)}
                    />
                    <input
                        type="password"
                        placeholder="Confirm"
                        className="signup-input w-1/2"
                        value={companyConfirmPassword}
                        onChange={(e) => handleInputChange(setCompanyConfirmPassword)(e)}
                    />
                </div>
                <div className="relative flex w-full items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="signup-remeber-checkbox"
                            type="checkbox"
                            className="w-3 h-3 accent-black mr-3"
                            checked={companyAcceptTerms}
                            onChange={handleCompanyAcceptTermsChange}
                        />
                        <p className="text-p text-left">I accept the terms and conditions.</p>
                    </div>
                </div>
                <div className="relative w-full flex mt-4">
                    <button onClick={handleCompanySignup} id="signup-btn">
                        Get started
                    </button>
                </div>
                <p id="signup-error-msg" className="text-red mt-4"></p>
            </div>
        </>
    );
}