import React from 'react';
import {VectorMap} from '@react-jvectormap/core';
import {worldMill} from '@react-jvectormap/world';
import countriesData from './countries.json';

export default function WorlMap() {

    return (
        <div className='w-full h-[90%]'>
            <VectorMap 
                map={worldMill}
                backgroundColor="transparent"
                series={{
                    regions: [
                        {
                            attribute: 'fill',
                            scale: ['#3edf9f', '#1B75F8'], 
                            normalizeFunction: 'polynomial',
                            values: countriesData
                        },
                    ],
                }}
            />
        </div>
    );
}

