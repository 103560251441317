import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login, SignUp, About, Terms, Support, Policy, NotFoundPage} from './pages/index';
import Agent from './agent';
import Company from './company';

function App() {

  return (
    <div id="app" className='relative flex flex-col text-center min-h-screen'>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/agent/*" element={<Agent />} />
          <Route path="/company/*" element={<Company />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
    </div>
  );
}

export default App;
