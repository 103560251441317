import React from "react";
import './header.css';

import { useUserData } from '../Context/agentContext';

import Email from "../../content/images/icons/email.png";
import Pin from "../../content/images/icons/pin.png";

// import { getCompany } from '../../services/companies';
// import Cookies from "js-cookie";

// TODO: fix this
// const savedCompanyData = Cookies.get('userData');
// const companyData = savedCompanyData ? JSON.parse(savedCompanyData) : null;
// const company = getCompany(companyData.id);

function Header() {
  const { user } = useUserData();

  return (
    <div id="header" className="relative w-full flex flex-col justify-start items-center">
        <div id="header-profile" className="card relative flex justify-between items-center px-20 py-10 w-full gap-10">
            <div id="header-profile-personal-info" className="relative flex flex-col justify-start items-start w-1/2 text-left">
                <div className="relative mb-4">
                    <h3 className="text-h3">{user?.name || "Loading..."}</h3>
                    <h4 className="text-h4">{user?.sector || "Loading..."}</h4>
                </div>
                <div id="header-profile-contact-info" className="relative flex flex-col justify-center items-start text-p">
                    <div className="relative flex justify-start items-center mb-2 w-full ">
                        <img src={Email} alt="email" className="w-4 mr-5 invert"/>
                        <p>{user?.email || "Loading..."}</p>
                    </div>
                    <div className="relative flex justify-start items-center w-full mb-4">
                        <img src={Pin} alt="email" className="w-4 mr-5 invert"/>
                        <p className="relative w-full">{user?.city || "..."}, {user?.country || "..."}</p>
                    </div>
                </div>
                <div id="header-profile-box" className="relative flex w-4/5 h-7 justify-center items-center">
                    <div id="header-profile-level" className="absolute h-full left-0 bg-[var(--stats)]"></div>
                    <p className="text-p">level {user?.level || "..."} - {user?.nextLevel || "..."}%</p>
                </div>
            </div>
            <div id="header-profile-dashboard-info" className="relative flex text-center items-center justify-center gap-4">
                <div className="info-card card relative flex flex-col justify-center items-center p-3">
                    <p className="font-bold">Candidates</p>
                    <h2 className="text-[var(--stats)] text-h3">{user?.totalCandidates || "..."}</h2>
                </div>
                <div className="info-card card relative flex flex-col justify-center items-center p-3">
                    <p className="font-bold">Projects</p>
                    <h2 className="text-[var(--stats)] text-h3">{user?.totalProjects || "..."}</h2>
                </div>
            </div>
            <div id="header-img" className="relative flex w-[15rem]">
                <img src={user?.image || "..."} alt="img" className="relative w-[15rem] rounded-full"/>
            </div>
        </div>
    </div>
  );
}

export default Header;
