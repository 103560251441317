import React, { useState } from "react";

import '../projects.css';

import Prompt from "../../../components/Prompt/prompt";

import HeaderTop from '../../../components/HeaderTop/header-top';
import Header from '../../../components/Header/project-header';
import CoursesRecommended from "../../../components/Projects/recommendedCourses";
import { useNavigate, useParams } from "react-router-dom";
import { useUserData } from '../../../components/Context/agentContext';
import { SubmitProject, UnsubscribeProject } from "../../../services/projects";
import projectData from "../../../jsons/project.json";

function ProjectPage() {

	const [fileContent, setFileContent] = useState<File | null>(null);
	const navigate = useNavigate();
	const { user } = useUserData();
	const { projectId = "1" } = useParams<{ projectId?: string }>();
	const [project, setProject] = React.useState<any>(null);
	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	// TODO - Get project from API
	React.useEffect(() => {
		const index = parseInt(projectId, 10) - 1;
		setProject(projectData.projects[index]);
	}, [projectId]);

	// React.useEffect(() => {
	// 	GetProject(projectId, (projectData:ProjectInterface) => {
	//         setProject(projectData);
	//     });
	// }, [projectId]);

	if (!project) return <div>Loading...</div>;


	function handleGiveup() {
		setPrompt({
			message: "Are you sure you want to give up this project?",
			load: true,
			action: "giveup"
		});
	}

	function handleSubmition() {
		setPrompt({
			message: "Are you sure you want to submit your project? Once you do you cannot submit it again.",
			load: true,
			action: "submit"
		})
	}

	const question = (answer: boolean) => {
		if (answer) {
			if (prompt.action === "giveup") {
				UnsubscribeProject(projectId);
				window.location.href = "/agent/projects";
			}
			if (prompt.action === "submit") {
				SubmitProject(projectId, fileContent);
				window.location.href = `/agent/projects/${projectId}/${user?.username}/eval`;
			}
			return;
		}
		setPrompt({
			message: "",
			load: false,
			action: ""
		});
	}

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files && event.target.files[0];
		if (file && file.name.endsWith('.zip')) {
			setFileContent(file);
		} else {
			alert("Please upload a valid .zip file");
		}
	};

	const openFileDialog = () => {
		const fileInput = document.getElementById("upload-file-input");
		fileInput?.click();
	};

	function ProjectInfo() {

		return (
			<div id="subscribed-project-info" className="relative w-full flex gap-4">
				<div className="card flex flex-col gap-4 w-full p-6 text-left justify-start">
					<h2 className="text-h4">Your project</h2>
					<div className="subscribed-project-info-cards flex gap-4 w-full h-full items-center">
						<div className="invert-card relative flex flex-col p-5 h-[8rem] text-center w-full justify-between">
							<h5 className="text-h5 text-center w-full mb-2">Subject:</h5>
							<div className="flex items-center justify-center">
								{/* Por subject na interface do projeto */}
								<button className="relative bg-blue w-[9rem] py-[3px] rounded-full text-white"><a href={project?.name || "Loading..."} rel="noopener noreferrer" target="_blank">open</a></button>
								{/* <img src={DownloadIcon} alt="img" className="h-7 cursor-pointer" /> */}
							</div>
						</div>
						<div className="invert-card relative flex flex-col p-5 h-[8rem] text-center w-full justify-between">
							<h5 className="text-h5 text-center w-full mb-2">My project:</h5>
							<div className="flex items-center justify-center">
								<button
									id="upload-file-fake-input"
									className="relative bg-blue w-[9rem] py-[3px] rounded-full text-white"
									onClick={openFileDialog} // trigger the file dialog open
								>
									Upload
								</button>
								<input
									id="upload-file-input"
									className="hidden"
									type="file"
									placeholder="upload"
									onChange={handleFileChange} // set up the change event handler
								/>
							</div>
						</div>
					</div>
					<div id="subscribed-project-info-btns" className="invert-card relative flex justify-center gap-5 p-5">
						<button onClick={() => handleSubmition()} className="relative bg-green w-[9rem] py-[3px] rounded-full text-white">submit project</button>
					</div>
				</div>
				<div className="card flex flex-col gap-4 w-full p-6 text-left justify-center">
					{
						project?.type === "Vacancy" &&
						<>
							<h2 className="text-h4">Project Info</h2>
							<div className="subscribed-project-info-cards flex gap-4 w-full">
								<div className="invert-card relative flex flex-col p-5 text-center w-full justify-between">
									<h5 className="text-h5 w-full mb-2">Area:</h5>
									<h4 className="text-h4"><b>{project?.area || "Loading..."}</b></h4>
								</div>
								<div className="invert-card relative flex flex-col p-5 text-center w-full justify-between">
									<h5 className="text-h5 w-full mb-2">Technology:</h5>
									<h4 className="text-h4"><b>{project?.tech || "Loading..."}</b></h4>
								</div>
							</div>
						</>
					}
					<div id="subscribed-project-info-btns" className="relative flex justify-center gap-5 p-5">
						<button onClick={() => navigate(`/agent/projects/${project?.id}`)} className="relative bg-[var(--yellow)] w-[9rem] py-[3px] rounded-full text-black">project page</button>
						<button onClick={() => handleGiveup()} className="relative bg-red w-[9rem] py-[3px] rounded-full text-white">give up</button>
					</div>
				</div>
			</div>
		);
	}
	function ProjectCards() {
		return (
			<div id="subscribed-project-page-cards" className="relative w-full gap-4 my-4">
				<ProjectInfo />
			</div>
		);
	}

	return (
		<div className="projects-page relative w-full flex flex-col justify-start items-start min-h-screen">
			<HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null} />
			<Header />
			<ProjectCards />
			<CoursesRecommended />
			<Prompt onDialog={question} opened={prompt.load} message={prompt.message} type={"confirm"} />
		</div>
	);
}

export default ProjectPage;
