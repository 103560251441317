import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import '../projects.css';

import HeaderTop from '../../../components/HeaderTop/header-top';
import Header from '../../../components/Header/project-header';
// import CoursesRecommended from "../../../components/Projects/recommendedCourses";

import { useUserData } from '../../../components/Context/agentContext';
// import { GetProject } from "../../../services/projects";

// import Cookies from 'js-cookie';
// import { getUser } from '../../../services/users';

// TODO: fix this
// const savedUserData = Cookies.get('userData');
// const userData = savedUserData ? JSON.parse(savedUserData) : null;
// const user = getUser(userData.id);

import projectData from "../../../jsons/project.json";


function ProjectPage() {

	const { projectId = "1" } = useParams<{ projectId?: string }>();
	const navigate = useNavigate();
	const [project, setProject] = React.useState<any>(null);

	// TODO - Get project from API
	React.useEffect(() => {
		const index = parseInt(projectId, 10) - 1;
		setProject(projectData.projects[index]);
	}, [projectId]);
	
    // React.useEffect(() => {
	// 	GetProject(projectId, (projectData:ProjectInterface) => {
    //         setProject(projectData);
    //     });
    // }, [projectId]);
	
	if (!project) return <div>Loading...</div>;

	function EvalItem({...props}) {
	  return (
		<Link to={'/agent/projects/projectName/other-username/eval'}>
		  <div className="eval-item relative flex justify-between items-center w-full h-[4rem] text-left px-4 rounded-[18px] mb-5 cursor-pointer ">
			<div className="relative flex flex-col">
			  <h3 className="text-h4">{props.name}</h3>
			  <h3 className="text-h5">from {props.user}</h3>
			</div>
			<div className="relative flex-flex-col justify-between items-center">
			  <h1>→</h1>
			</div>
		  </div>
		</Link>
	  );
	}
	
	function ProjectInfo({...props}) {
		const {user} = useUserData();
		return (
			<div id="eval-project-page-info" className="relative w-full flex gap-4 mt-4">
				<div className="card flex flex-col gap-4 w-full h-[23rem] p-6 text-left justify-start">
					<h2 className="text-h4">Your project</h2>
					<div  className="eval-project-page-info-cards flex gap-4 w-full h-full items-center">
						<div className="invert-card relative flex flex-col p-5 h-[8rem] text-center w-full justify-between">
							<h5 className="text-h5 text-center w-full mb-2">Subject:</h5>
							<div className="flex items-center justify-center">
								<button className="relative bg-blue w-[9rem] py-[3px] rounded-full text-white"><a href={require('../../../content/dumb.pdf')} rel="noreferrer" target="_blank">open</a></button>
								{/* <img src={DownloadIcon} alt="img" className="h-7 cursor-pointer" /> */}
							</div>
						</div>
						<div className="invert-card relative flex flex-col p-5 h-[8rem] text-center w-full justify-between">
							<h5 className="text-h5 text-center w-full mb-2">My project:</h5>
							<div className="flex items-center justify-center">
								<button className="relative bg-blue w-[9rem] py-[3px] rounded-full text-white">download</button>
							</div>
						</div>
					</div>
					<div id="eval-project-page-info-btns" className="invert-card relative flex justify-center gap-5 p-5">
						<button onClick={() => navigate(`/agent/projects/${project?.id}/${user?.username}/done`)} className="relative bg-blue w-[9rem] py-[3px] rounded-full text-white">done</button>
						<button onClick={() => navigate(`/agent/projects/${project?.id}`)} className="relative bg-[var(--yellow)] w-[9rem] py-[3px] rounded-full text-black">project page</button>
					</div>
				</div>
				<div id="sub-project-eval-card" className="relative card w-full h-[23rem] overflow-y-scroll flex flex-col justify-start items-center px-10 pt-5">
					<h4 className="text-h4 text-left w-full mb-6">Evaluate others</h4>
					<div id="sub-project-eval-card-list" className="relative flex flex-col w-full">
						{
							user?.evaluations.map((evalu: any) => {
								return <EvalItem key={evalu.id} name={evalu.name} user={evalu.user} deadline={evalu.deadline} />
							})
						}
					</div>
				</div>
			</div>
		);
	}
	
	return (
		<div className="projects-page relative w-full flex flex-col justify-start items-start min-h-screen">
			<HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null} />
			<h1 className="font-bold mb-4 ml-4">You must now evaluate other agents</h1>
			<Header />
			<ProjectInfo name="Automatic Systems" desc="The Worklab Dashboard is a cutting-edge platform that combines artificial intelligence with intuitive interfaces to optimize the talent acquisition process. With intelligent candidate sourcing, automated resume screening, predictive candidate ranking, and collaborative evaluation, this platform streamlines recruitment efforts. It provides performance analytics, empowering data-driven decision-making, and offers a personalized candidate experience. The Worklab Dashboard revolutionizes talent acquisition, improving efficiency, reducing bias, and driving the company's success in the global market." area="AI" tech="Python" deadline="12/10/2023" type="Vacancy"/>
			{/* <CoursesRecommended/> */}
		</div>
	);
}

export default ProjectPage;
