import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function FiltersMenu({setShowFilters}:any) {

    const [companyName, setCompanyName] = useState("");
    const [area, setArea] = useState("");
    const [tech, setTech] = useState("");
    const [wage, setWage] = useState(0);
    const [workMethod, setWorkMethod] = useState("All");
    const [contractType, setContractType] = useState("All");
    const [deadline, setDeadline] = useState("Default");

    const navigate = useNavigate();

    const applyFilters = () => {    
        let queryParams = new URLSearchParams();
    
        if (companyName) queryParams.append("companyName", companyName);
        if (area) queryParams.append("area", area);
        if (tech) queryParams.append("tech", tech);
        if (wage) queryParams.append("wage", wage.toString());
        if (workMethod !== "All") queryParams.append("workMethod", workMethod);
        if (contractType !== "All") queryParams.append("contractType", contractType);
        if (deadline !== "Default") queryParams.append("deadline", deadline);
    
        navigate(`${window.location.pathname}?${queryParams.toString()}`);
        // setShowFilters(false);
    };
    
    const resetFilters = () => {
        setCompanyName("");
        setArea("");
        setTech("");
        setWage(0);
        setWorkMethod("All");
        setContractType("All");
        setDeadline("Default");
        navigate(`${window.location.pathname}`);
        setShowFilters(false);
    };

    return (
        <div id="filters-menu" className="invert-card absolute top-[4rem] left-[0rem] bg-white z-[5] p-5 flex flex-col gap-4 min-w-[17rem]">
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Search company:</h4>
                <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={companyName} onChange={e => {setCompanyName(e.target.value)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Search area:</h4>
                <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={area} onChange={e => {setArea(e.target.value)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Search technology:</h4>
                <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={tech} onChange={e => {setTech(e.target.value)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Min Wage:</h4>
                <input type="number" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" min="0" onChange={e => {setWage(parseFloat(e.target.value) || 0)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Method of Work:</h4>
                <select value={workMethod}  onChange={e => setWorkMethod(e.target.value)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.3rem] w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
                    <option className="relative bg-blue" value="All">All</option>
                    <option className="relative bg-blue" value="Hybrid">Hybrid</option>
                    <option className="relative bg-blue" value="Remote">Remote</option>
                    <option className="relative bg-blue" value="In Office">In Office</option>
                </select>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Contract Type:</h4>
                <select value={contractType}  onChange={e => setContractType(e.target.value)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.3rem] w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
                    <option className="relative bg-blue" value="All">All</option>
                    <option className="relative bg-blue" value="Full-time">Full-time</option>
                    <option className="relative bg-blue" value="Part-time">Part-time</option>
                    <option className="relative bg-blue" value="Project-only">Project-only</option>
                </select>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Deadline:</h4>
                <div className="relative flex gap-4">
                    <div className="relative flex items-center gap-2">
                        <p className="text-h5">Default</p>
                        <input id="filters-deadline-default" checked={deadline === "Default"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("Default")}/>
                    </div>
                    <div className="relative flex items-center gap-2">
                        <p className="text-h5">&gt; 1 month</p>
                        <input id="filters-deadline-more" checked={deadline === "more"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("more")}/>
                    </div>
                </div>
            </div>
            <div className='flex gap-4'>
                <button onClick={applyFilters} className='bg-blue w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>apply</button>
                <button onClick={resetFilters} className='bg-[var(--yellow)] text-black w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>reset</button>
            </div>
        </div>
    );
}

export default function ExploreFilters() {
    const [showFilters, setShowFilters] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current && 
                menuRef.current.contains(event.target as Node) && 
                buttonRef.current && 
                buttonRef.current.contains(event.target as Node)
            ) {
                setShowFilters(false);
            }
        };
        

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button id="header-filters" onClick={() => setShowFilters(!showFilters)} ref={buttonRef} className="card relative flex justify-center items-center mr-4 seis:hidden">
                <div className="mr-2 flex flex-col justify-between items-center h-[.7rem]">
                    <div className="w-[15px] bg-white h-[1.5px]"></div>
                    <div className="w-[10px] bg-white h-[1.5px] "></div>
                    <div className="w-[5px] bg-white h-[1.5px] "></div>
                </div>
                <p>Filters</p>
            </button>
            {showFilters && <div ref={menuRef}><FiltersMenu setShowFilters={setShowFilters} /></div>}
        </>
    );
}

