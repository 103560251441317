function CoursesCard({ ...props }) {
	return (
		<div className="courses-card card relative flex flex-col cursor-pointer">
			<div className="courses-card-top relative flex h-3/5">
				<img src={props.img} alt="img" className="relative w-full" />
			</div>
			<div className="courses-card-bottom relative flex flex-col justify-between h-2/5 px-5 py-2">
				<p className="text-p text-left">{props.about}</p>
				<div className="relative flex justify-between items-center ">
					<h5 className="text-h5"><b>{props.company}</b></h5>
					<div className="relative flex flex-col">
						<h5 className="text-h5 text-right"><b>{props.participants}</b> px.</h5>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CoursesCard;