import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navbar, OtherProfile, Profile, Projects, Explore, ProjectPage, SubscribedProject, EvalProject, DoneProject, Courses, Certifications, EvaluationPage, Settings, NotFoundPage } from './agent/index';

import { UserDataProvider } from './components/Context/agentContext';
import Footer from './components/Footer/footer';

function Agent() {

	const location = useLocation();

	useEffect(() => {
		document.getElementById('navbar')?.classList.remove('active');
		document.getElementById('menu-btn')?.classList.remove('active');
		document.body.style.overflow = 'auto';
		window.scroll(0, 0);
	}, [location]);

	const routes = [
		{
			path: "/:username",
			element: <OtherProfile />
		},
		{
			path: "/profile",
			element: <Profile />
		},
		{
			path: "/projects",
			element: <Projects />
		},
		{
			path: "/explore",
			element: <Explore />
		},
		{
			path: "/projects/:projectId",
			element: <ProjectPage />
		},
		{
			path: "/projects/:projectId/:username",
			element: <SubscribedProject />
		},
		{
			path: "/projects/:projectId/:username/eval",
			element: <EvalProject />
		},
		{
			path: "/projects/:projectId/:username/done",
			element: <DoneProject />
		},
		{
			path: "/projects/:projectId/:other-username/eval",
			element: <EvaluationPage />
		},
		{
			path: "/courses",
			element: <Courses />
		},
		{
			path: "/achievements",
			element: <Certifications />
		},
		{
			path: "/settings/",
			element: <Settings />
		},
		{
			path: "/settings/:settingsPage",
			element: <Settings />
		},
	]
	return (
		<UserDataProvider>
			<div id='agent' className='relative flex flex-col text-center font-GilroyLight min-h-screen w-full pr-4'>
				<div className='flex'>
					<Navbar />
					<div className='page relative pl-[8rem] w-full'>
						<Routes>
							<Route index element={<Profile />}></Route>
							{routes.map((route) => {
								return (
									<Route
										key={route.path}
										path={route.path}
										element={route.element}>
									</Route>
								)
							})}
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</div>
				</div>
				<Footer />
			</div>
		</UserDataProvider>
	);
}

export default Agent;
