import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function FiltersMenu({setShowFilters}:any) {
    const [companyName, setCompanyName] = useState("");
    const [area, setArea] = useState("");
    const [workLevel, setWorkLevel] = useState("All");

    const navigate = useNavigate();

    const applyFilters = () => {
        let queryParams = new URLSearchParams();

        if (companyName) queryParams.append("companyName", companyName);
        if (area) queryParams.append("area", area);
        if (workLevel !== "All") queryParams.append("workLevel", workLevel);

        navigate(`${window.location.pathname}?${queryParams.toString()}`);
        // setShowFilters(false);
    };

    const resetFilters = () => {
        setCompanyName("");
        setArea("");
        setWorkLevel("All");
        navigate(`${window.location.pathname}`);
        setShowFilters(false);
    };

    return (
        <div id="filters-menu" className="invert-card absolute top-[4rem] left-[0rem] bg-white z-[5] p-5 flex flex-col gap-4 min-w-[17rem]">
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Search company:</h4>
                <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={companyName} onChange={e => {setCompanyName(e.target.value)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Search area:</h4>
                <input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={area} onChange={e => {setArea(e.target.value)}}/>
            </div>
            <div className="relative flex flex-col">
                <h4 className="text-h5 text-left w-full mb-2">Level of Work:</h4>
                <select value={workLevel} onChange={e => setWorkLevel(e.target.value)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.3rem] w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
                    <option className="relative bg-blue" value="All">All</option>
                    <option className="relative bg-blue" value="Beginner">Beginner</option>
                    <option className="relative bg-blue" value="Intermediate">Intermediate</option>
                    <option className="relative bg-blue" value="Expert">Expert</option>
                </select>
            </div>
            <div className='flex gap-4'>
                <button onClick={applyFilters} className='bg-blue w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>apply</button>
                <button onClick={resetFilters} className='bg-[var(--yellow)] text-black w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>reset</button>
            </div>
        </div>
    );
}

export default function CoursesFilters() {
    const [showFilters, setShowFilters] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                menuRef.current && 
                !menuRef.current.contains(event.target as Node) && 
                !buttonRef.current?.contains(event.target as Node)
            ) {
                setShowFilters(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <button id="header-filters" onClick={() => setShowFilters(!showFilters)} ref={buttonRef} className="card relative flex justify-center items-center mr-4 miletres:hidden">
                <div className="mr-2 flex flex-col justify-between items-center h-[.7rem]">
                    <div className="w-[15px] bg-white h-[1.5px]"></div>
                    <div className="w-[10px] bg-white h-[1.5px] "></div>
                    <div className="w-[5px] bg-white h-[1.5px] "></div>
                </div>
                <p>Filters</p>
            </button>
            {showFilters && <div ref={menuRef}><FiltersMenu setShowFilters={setShowFilters}/></div>}
        </>
    );
}
