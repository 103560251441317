import React from 'react';
import './cards.css';

import ProjectChart from './projectChart';

import Calendar from '../../../components/Calendar/calendar';
import ProfileImg from "../../../content/images/others/profile.jpg";

import { useUserData } from '../../../components/Context/agentContext';
import {BsLightningFill} from '@react-icons/all-files/bs/BsLightningFill';
import {FiPaperclip} from '@react-icons/all-files/fi/FiPaperclip';

// import Cookies from 'js-cookie';
// import { getCompany } from '../../../services/companies';

// TODO: fix this
// const savedCompanyData = Cookies.get('userData');
// const companyData = savedcompanyData ? JSON.parse(savedCompanyData) : null;
// const company = getcompany(companyData.id);

function Vacancy({role, vacanciesOpened} : {role:string, vacanciesOpened:string}) {
  return (
    <div onClick={() => window.location.replace("/company/projects/projectName")} className="profile-vacancy relative flex justify-between items-center w-full mb-6 pb-5">
      <div className="relative flex justify-start items-center w-[40%]">
        <FiPaperclip color='var(--text)' className='w-6 h-6 mr-8'/>
        <h2 className="mr-10">{role}</h2>
      </div>
      <h3 id='vacancy-card-number' className="text-[var(--yellow)]"># {vacanciesOpened}</h3>
      <button className="mr-5 bg-blue rounded-full px-5 text-white">view</button>
    </div>
  );
}

function VacancyCard() {
  const { user } = useUserData();
  return (
    <div id="vacancy-card" className="card p-8">
      <div className="relative flex justify-between">
        <h4 className="text-h4 text-center mb-10">Opened roles</h4>
        <p onClick={() => window.location.replace("/company/projects")} className="cursor-pointer hover:text-yellow">see all →</p>
      </div>
      <div className="relative flex w-full text-left">
        <div className="relative flex flex-col w-full text-left">
          {user?.projects.filter((project:any) => !project?.closed || false).map((project:any) => {
              return (<Vacancy key={project?.id} role={project?.role || "Loading..."} vacanciesOpened={project?.vacancies || "..."}/>);
          })}
        </div>
      </div>
    </div>
  );
}

function Member({...props}) {
  return (
    <div className="member-item relative flex justify-between items-center w-full h-[4rem] text-left px-4 rounded-[18px] mb-4 cursor-pointer ">
      <div className="relative flex items-center">
        <img src={ProfileImg} alt="img" className="relative w-8 rounded-full mr-5" />
        <h3 className="text-h5 mr-10">{props.name}</h3>
      </div>
      <div className="relative flex-flex-col justify-between items-center">
        <h1>→</h1>
      </div>
    </div>
  );
}

function Members() {
  const { user } = useUserData();
  return (
    <div id="member-card" className="card relative flex flex-col justify-start items-center p-8">
      <div className="relative flex justify-between w-full mb-10">
        <div  className="relative flex items-center">
          <h4 className="text-h4 text-center">Members</h4>
        </div>
        <p onClick={() => window.location.replace("/company/settings/members")} className="cursor-pointer hover:text-yellow">see all →</p>
      </div>
      <div id="member-list" className="relative flex flex-col w-full">
        {(user?.members || []).map((member:any) => {
          return (<Member key={member.id} name={member.name}/>);
        })}
      </div>
    </div>
  );
}

function StatsCard() {
  return (
    <div id="company-card-stats" className="relative card p-5 px-0">
      <h1 className="text-h4 mb-10">Company Stats</h1>
      <div className='relative w-full h-full pb-10'>
        <ProjectChart />
      </div>
    </div>
  );
}

function Project({...props}) {
  return (
    <div onClick={() => window.location.replace(`/company/projects/${props.id}`)} className="done-project relative flex flex-col justify-center items-center cursor-pointer">
      <div className="done-project-top relative w-full flex flex-col justify-start items-start p-4">
        <div className="relative h-1/2">
          <h4 className="text-h5 text-left"><b>{props.name}</b></h4>
        </div>
        <div className="relative w-full flex justify-center items-center h-1/2">
          <BsLightningFill color='white' className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6'/>
        </div>
      </div>
      <div className="home-project-card-line"></div>
      <div className="done-project-bottom relative w-full flex justify-center items-center p-3">
        <h5 className="done-project-eval text-h5 text-white ">{props.px} candidates</h5>
      </div>
    </div>
  );
}

function HistoryProjectsCard() {
  const { user } = useUserData();
  return (
    <div id="card-done-projects" className="card p-5 relative flex flex-col justify-between">
      <h4 className="text-h4 text-left mb-4">Done Projects</h4>
      <div id="card-done-projects-list" className="relative text-white px-5 pb-5">
        {user?.projects.map((project: any) => {
						return <Project key={project?.id} 
              id={project?.id || 1}
              name={project?.name || "Loading..."} 
              company={project?.companyName || "Loading..."} 
              px={project?.vacancies || "..."} 
              />
					})
				}
        <div id="more-projects-card" className=" relative flex flex-col justify-between py-4 items-center text-[var(--text)]">
          <div>
            <h1>Want more?</h1>
            <p className="text-p">Search for new projects</p>
          </div>
          <BsLightningFill color='white' className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-6 h-6'/>
          <button id="button-more-projects" onClick={()=> window.location.replace("/company/projects")} className="button-project px-3">Take me there</button>
        </div>
      </div>
    </div>
  );
}

function CalendarCard() {
	const { user } = useUserData();
	return (
		<div id="card-calendar" className="card relative flex flex-col w-full justify-start items-start overflow-y-scroll p-4">
			<h1 className='w-full text-left text-h4 mb-4'>Calendar</h1>
			<div className='flex flex-col gap-4 justify-between w-full px-'>
				<div className='w-full text-left flex gap-4'>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-red'></div>Deadlines</div>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-[var(--yellow)]'></div>End fase 1</div>
					<div className='text-h5 flex gap-4 items-center'><div className='w-2 h-2 rounded-full bg-blue'></div>End fase 2</div>
				</div>
				<Calendar eventsList={user?.events || []} />
			</div>
		</div>
	);
}

export { 
  CalendarCard,
  StatsCard,
  Members,
  VacancyCard,
  HistoryProjectsCard
};
