import React, {useState} from "react";
import ProfileSettings from './settings/profile'
import SecuritySettings from './settings/security'
import MembersSettings from './settings/members'
import './settings.css';
import { useParams } from "react-router-dom";

function Section({section} : {section:string}) {
    if (section === "profile") {
        return <ProfileSettings/>;
    } else if (section === 'security') {
        return <SecuritySettings/>;
    } else if (section === 'members') {
        return <MembersSettings/>;
    } else {
        return null;
    }
}

function Settings() {
    const [section, setSection] = useState("profile");

    const { settingsPage = "profile" } = useParams<{ settingsPage?: string }>();
    React.useEffect(() => {
        setSection(settingsPage);
    }, [settingsPage]);

    function CustomLink( { href , children } : {href:string, children:string} ) {
        let className = "settings-navbar-link px-4 py-1 flex items-center justify-center rounded-full ";
        if (section === href) {
          className += "selected bg-blue";
        }
        return (
          <li onClick={() => setSection(href)} className={className}>
              <p className="relative settings-link cursor-pointer">{children}</p>
          </li>
        );
    }

    const toggleMenu = () => {
        const navbar = document.getElementById('navbar');
        const menuBtn = document.getElementById('menu-btn');
        navbar?.classList.toggle('active');
        menuBtn?.classList.toggle('active');
    };
    
  return (
    <div id="settings-all" className="company relative flex flex-col w-full mr-5 px-10 min-h-screen">
        <div className="relative w-full flex justify-between items-center text-h2 text-left p-5">
            <h1>Settings</h1>
            <div onClick={toggleMenu} id="menu-btn" className="relative w-[20px] h-7 flex flex-col justify-evenly items-center">
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
            </div>
        </div>
        <div id="settings-navbar" className="relative h-full flex text-left py-2 my-6 gap-4 ">
            <CustomLink href="profile">Account</CustomLink>
            <CustomLink href="security">Security</CustomLink>
            <CustomLink href="members">Members</CustomLink>
            <li onClick={() => {window.localStorage.removeItem('login'); window.location.replace("/")}} className="settings-navbar-link px-4 flex items-center justify-center rounded-full ">
                <p className="relative settings-link cursor-pointer">Sign out</p>
            </li>
        </div>
        <div id="settings-container"  className="relative w-full flex h-full">
            <Section section={section}/>
        </div>
    </div>
  );
}

export default Settings;
