import React, { useEffect, useState } from "react";

import { Country, State } from 'country-state-city';
import { register } from "../../services/authentication.js";

import "./signup.css";

export default function AgentForm() {

    // USER
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    const handleInputChange =
        (setStateFn: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setStateFn(e.target.value);
            };

    const handleSelectChange =
        (setStateFn: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLSelectElement>) => {
                setStateFn(e.target.value);
            };

    const handleAcceptTermsChange = (e: any) => {
        setAcceptTerms(e.target.checked);
    };

    // USER SIGN UP
    const handleSignup = () => {
        function isValidEmail(email: string): boolean {
            const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            return emailPattern.test(email);
        }

        let error = document.getElementById(
            "signup-error-msg"
        ) as HTMLParagraphElement;

        const validations = [
            { check: firstName, message: "First name is required" },
            { check: lastName, message: "Last name is required" },
            { check: username, message: "Username is required" },
            { check: email && isValidEmail(email), message: "Enter a valid email address" },
            { check: password, message: "Password is required" },
            { check: confirmPassword, message: "Confirm you password" },
            { check: password === confirmPassword, message: "Passwords do not match" },
            { check: acceptTerms, message: "Please read and accept the T&C" },
        ];

        for (const validation of validations) {
            if (!validation.check || validation.check === "") {
                error.innerText = validation.message;
                return;
            }
        }

        register(firstName, lastName, city, country, email, username, password, confirmPassword)
        .then((response) => {
            window.location.href = "/agent/profile";
        })
        .catch((err) => {
            error.innerText = "Registration failed. Please try again.";
        });
    };

    // Get Country and Cities
    const countryData = Country.getAllCountries();

    const [countries, setCountries] = useState(countryData);
    const [countrySelected, setCountrySelected] = useState(countryData[0]);
    const [cities, setCities] = useState(State.getStatesOfCountry(countrySelected?.isoCode));

    const handleCountrySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedCountry = countries.find(country => country.name === e.target.value);
    
        if (selectedCountry) {
            setCountrySelected(selectedCountry);
            setCountry(selectedCountry.name);
            const newCities = State.getStatesOfCountry(selectedCountry.isoCode);
            setCities(newCities);
        }
    };
    

    // Sort countries
    useEffect(() => {
        const sortedCountries = [...countryData].sort((a, b) => a.name.localeCompare(b.name));
        setCountries(sortedCountries);
    }, [countryData]);

    return (
        <>
            <div className="relative flex flex-col w-full">
                <div className="flex">
                    <input
                        type="text"
                        placeholder="First Name"
                        className="signup-input w-1/2 mr-5"
                        value={firstName}
                        onChange={(e) => handleInputChange(setFirstName)(e)}
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        className="signup-input w-1/2"
                        value={lastName}
                        onChange={(e) => handleInputChange(setLastName)(e)}
                    />
                </div>
                <div className="flex">
                    <select
                        id="signup-country-select"
                        defaultValue={"Country"}
                        onChange={(e) => handleCountrySelect(e)}
                        className="signup-input w-1/2 mr-5 relative bg-[var(--inputbg)] outline-0 px-4 py-2 border-none text-p text-white cursor-pointer"

                    >
                        <option disabled>Country</option>
                        {countries.map(country => (
                        <option key={country.isoCode} value={country.name} style={{ backgroundColor: 'var(--blue)' }}>
                            {country.name}
                        </option>
                        ))}
                    </select>
                    <select
                        id="signup-city-select"
                        defaultValue={"City"}
                        onChange={(e) => handleSelectChange(setCity)(e)}
                        className="signup-input w-1/2 relative bg-[var(--inputbg)] outline-0 px-4 py-2 border-none text-p text-white cursor-pointer"
                    >
                        <option disabled>City</option>
                        {cities.map(city => (
                        <option key={city.name} value={city.name} style={{ backgroundColor: 'var(--blue)' }}>
                            {city.name}
                        </option>
                        ))}
                    </select>
                </div>
                <input
                    type="text"
                    placeholder="Email"
                    className="signup-input"
                    value={email}
                    onChange={(e) => handleInputChange(setEmail)(e)}
                />
                <input
                    type="text"
                    placeholder="Username"
                    className="signup-input"
                    value={username}
                    onChange={(e) => handleInputChange(setUsername)(e)}
                />
                <div className="flex">
                    <input
                        type="password"
                        placeholder="Password"
                        className="signup-input w-1/2 mr-5"
                        value={password}
                        onChange={(e) => handleInputChange(setPassword)(e)}
                    />
                    <input
                        type="password"
                        placeholder="Confirm"
                        className="signup-input w-1/2"
                        value={confirmPassword}
                        onChange={(e) => handleInputChange(setConfirmPassword)(e)}
                    />
                </div>
                <div className="relative flex w-full items-center justify-between">
                    <div className="flex items-center">
                        <input
                            id="signup-remeber-checkbox"
                            type="checkbox"
                            className="w-3 h-3 accent-black mr-3"
                            checked={acceptTerms}
                            onChange={handleAcceptTermsChange}
                        />
                        <p className="text-p text-left">
                            I accept the terms and conditions.
                        </p>
                    </div>
                </div>
                <div className="relative w-full flex mt-4">
                    <button onClick={handleSignup} id="signup-btn">
                        Get started
                    </button>
                </div>
                <p id="signup-error-msg" className="text-red mt-4"></p>
            </div>
        </>
    );
}
