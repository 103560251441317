import React, { useEffect, useState } from "react";
import '../settings.css';
import Profile from "../../../content/images/others/profile.jpg";
import { useUserData } from "../../../components/Context/agentContext";
import Prompt from "../../../components/Prompt/prompt";
import { Country, State } from 'country-state-city';
import { updateUser } from "../../../services/users.js";

// import Cookies from 'js-cookie';
// import { getUser } from '../../../services/users';

// TODO: fix this
// const savedUserData = Cookies.get('userData');
// const userData = savedUserData ? JSON.parse(savedUserData) : null;
// const user = getUser(userData.id);

function ProfileSettings() {

	const { user } = useUserData();

	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [country, setCountry] = useState("");
	const [city, setCity] = useState("");
	const [phone, setPhone] = useState("");
	const [birthday, setBirthday] = useState("");


	const handleInputChange = (setStateFn: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setStateFn(e.target.value);
	};

	const handleSelectChange =
		(setStateFn: React.Dispatch<React.SetStateAction<string>>) =>
			(e: React.ChangeEvent<HTMLSelectElement>) => {
				setStateFn(e.target.value);
	};

	function isValidEmail(email: string): boolean {
		const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
		return regex.test(email);
	}

	function updateInfo() {
		let error = document.getElementById('settings-error-msg') as HTMLParagraphElement;

		const validations = [
			{ check: firstName, message: 'First name is required!' },
			{ check: lastName, message: 'Last name is required!' },
			{ check: username, message: 'Username is required!' },
			{ check: email && isValidEmail(email), message: 'Enter a valid email address!' },
			{ check: country, message: 'Country is required!' },
			{ check: city, message: 'City is required!' },
			{ check: birthday, message: 'Birthday is required!' },
		];

		for (const validation of validations) {
			if (!validation.check || validation.check === "") {
				error.innerText = validation.message;
				return;
			}
		}

		updateUser(
			{ firstName, lastName, username, email, country, city, birthday },
			(response:any) => {
			  // Handle success
			  setPrompt({
				message: "Personal info updated successfully!",
				load: true,
				action: "save"
			  });
			  error.innerText = "";
			},
			(error:any) => {
			  // Handle error
			  error.innerText = error.response?.data?.message || "Error updating info!";
			}
		);  
	}

	// Get Country and Cities
	const countryData = Country.getAllCountries();

	const [countries, setCountries] = useState(countryData);
	const [countrySelected, setCountrySelected] = useState(countryData[0]);
	const [cities, setCities] = useState(State.getStatesOfCountry(countrySelected?.isoCode));

	const handleCountrySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedCountry = countries.find(country => country.name === e.target.value);
	
		if (selectedCountry) {
			setCountrySelected(selectedCountry);
			setCountry(selectedCountry.name);
			const newCities = State.getStatesOfCountry(selectedCountry.isoCode);
			setCities(newCities);
		}
	};
	
	useEffect(() => {
		if (user) {
			setFirstName(user?.firstName || "");
			setLastName(user?.lastName || "");
			setUsername(user?.username || "");
			setEmail(user?.email || "");
			setPhone(user?.phone || "");
			setBirthday(user?.birthday || "");
	
			// Find the country object from the countries array
			const foundCountry = countries.find(c => c.name === user.country);
	
			if (foundCountry) {
				setCountrySelected(foundCountry);
				setCountry(foundCountry.name);
	
				// Fetch and set cities for the found country
				const newCities = State.getStatesOfCountry(foundCountry.isoCode);
				setCities(newCities);
	
				// Set the user's city if available
				if (user.city) {
					setCity(user.city);
				}
			} else {
				// Set default values if user's country is not found
				setCountry('Country');
				setCity('City');
				setCities([]);
			}
		}
	}, [user, countries]);	

	// Sort countries
	useEffect(() => {
		const sortedCountries = [...countryData].sort((a, b) => a.name.localeCompare(b.name));
		setCountries(sortedCountries);
	}, [countryData]);

	return (
		<div id="profile-settings" className="settings-section relative flex flex-col w-full h-full px-10 pb-8">
			<div className="relative flex flex-col w-full h-full">
				<div id="profile-settings-img" className="relative flex justify-start items-center w-full mb-10">
					<img src={Profile} alt="img" className="w-40 rounded-fulls" />
					<h2 className="cursor-pointer ml-6">change</h2>
				</div>
				<h1 className="text-h3 text-left mb-8">Personal Info</h1>
				<div id="settings-personal-info" className="relative flex flex-col text-left w-full mb-10">
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">First name</p>
						<input type="text" value={firstName} onChange={e => handleInputChange(setFirstName)(e)} />
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Last name</p>
						<input type="text" value={lastName} onChange={e => handleInputChange(setLastName)(e)} />
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Username</p>
						<input type="text" value={username} onChange={e => handleInputChange(setUsername)(e)} />
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Email</p>
						<input type="email" value={email} onChange={e => handleInputChange(setEmail)(e)} />
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Country</p>
						<select
							id="signup-country-select"
							defaultValue={"Country"}
							onChange={(e) => handleCountrySelect(e)}
							value={country}
							className="appearance-none cursor-pointer"

						>
							<option disabled>Country</option>
							{countries.map(country => (
								<option key={country.isoCode} value={country.name} style={{ backgroundColor: 'var(--blue)' }}>
									{country.name}
								</option>
							))}
						</select>
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">City</p>
						<select
							id="signup-city-select"
							defaultValue={"City"}
							onChange={(e) => handleSelectChange(setCity)(e)}
							value={city}
							className="appearance-none cursor-pointer"
						>
							<option disabled>City</option>
							{cities.map(city => (
								<option key={city.name} value={city.name} style={{ backgroundColor: 'var(--blue)' }}>
									{city.name}
								</option>
							))}
						</select>
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Phone number</p>
						<input type="number" value={phone} onChange={e => handleInputChange(setPhone)(e)} />
					</div>
					<div className="flex mr-8 items-center gap-4">
						<p className="text-h5">Birthday</p>
						<input type="date" value={birthday} onChange={e => handleInputChange(setBirthday)(e)} />
					</div>
				</div>
				<p id="settings-error-msg" className="text-left w-full text-red mb-4"></p>
				<div id="profile-settings-btn" className="w-full flex justify-start mb-4">
					<button onClick={updateInfo} className="bg-blue px-8 py-1 rounded-full text-white">Save</button>
				</div>
			</div>
			<Prompt message={prompt.message} opened={prompt.load} onDialog={() => setPrompt({message: "", load: false, action: ""})} type="alert" />
		</div>
	);
}

export default ProfileSettings;
