import React, { useState } from "react";
import './courses.css';

import HeaderTop from '../../components/HeaderTop/header-top';
import Section from './section';
import Toggle from "../../components/Toggle/Toggle";


function Courses() {
	let [section, setSection] = useState(1);
	const [search, setSearch] = useState("");

	return (
		<div className="courses-page relative flex flex-col justify-start items-center w-full mr-4 mb-4 min-h-screen">
			<HeaderTop filters={true} searchBar={true} page={"courses"} search={search} setSearch={setSearch}/>
			<div className="courses-list relative flex flex-col justify-center items-center w-full gap-8 mt-4">
				<Toggle section={section} changeSection={setSection} first="Recommended" second="All courses" />
				<Section section={section} search={search} />
			</div>
		</div>
	);
}

export default Courses;
