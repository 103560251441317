import ProjectCard from '../../components/Projects/project-card';
import ProjectList from "../../jsons/project.json";

import { useLocation } from 'react-router-dom';
// import { GetProjects } from '../../services/projects';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Section({ section, search }:{section:number, search:string}) {
	const query = useQuery();

	const companyName = query.get('companyName') || "";
	const area = query.get('area') || "";
	const tech = query.get('tech') || "";
	const wageString = query.get('wage');
	const wage = wageString ? parseInt(wageString, 10) : 0;
	const workMethod = query.get('workMethod') || "All";
	const contractType = query.get('contractType') || "All";
	const deadline = query.get('deadline') || "Default";
	
	const projectsBeforeFilter = ProjectList.projects; // Or GetProjects() as per your TODO note.
	
	if (!projectsBeforeFilter)
		return null;
	
	let project = projectsBeforeFilter;
	
	// Filter by company name
	if (companyName !== '') {
		project = project.filter(p => p.company.toLowerCase().includes(companyName.toLowerCase()));
	}
	
	// Filter by area
	if (area !== '') {
		project = project.filter(p => p.area.toLowerCase().includes(area.toLowerCase()));
	}
	
	// Filter by technology
	if (tech !== '') {
		project = project.filter(p => p.tech.toLowerCase().includes(tech.toLowerCase()));
	}
	
	// Filter by wage
	if (wage > 0) {
		project = project.filter(p => p?.minWage === undefined || p.minWage >= wage);
	}

	
	// Filter by work method
	if (workMethod !== 'All') {
		project = project.filter(p => p?.method === undefined || p.method === workMethod);
	}
	
	// Filter by contract type
	if (contractType !== 'All') {
		project = project.filter(p => p?.contract === undefined || p.contract === contractType);
	}
	
	// Convert to date
	const convertToDate = (deadlineStr: string): Date => {
		const [day, month, year] = deadlineStr.split('.').map(Number);
		return new Date(`20${year}-${month}-${day}`);
	}
	
	// Filter deadline
	if (deadline !== 'Default') {
		const currentDate = new Date();
		const oneMonthFromNow = new Date();
		oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
	
		project = project.filter(p => {
			const projectDeadlineDate = convertToDate(p.deadline);
			return deadline === "more" ? projectDeadlineDate > oneMonthFromNow : projectDeadlineDate <= oneMonthFromNow;
		});
	}
	
	// Filter based on search
	if (search !== '') {
		project = project.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));
	}

	if (section === 2) {
		// Trainning projects
		return (
			<>
			{ project?.filter((project: any) => project?.type === "Training").length !== 0 ?
			  project?.filter((project: any) => project?.type === "Training").map((project: any) => {
				return (<ProjectCard
					key={project?.id || 0}
					project={project}
				/>);
			  })
			  :
			  <p>No projects found</p>
			}
		  </>
		);
	} else {
		// Vacancy projects
		return (
			<>
				{   project?.filter((project: any) => project?.type === "Vacancy").length !== 0 ?
			  		project?.filter((project: any) => project?.type === "Vacancy").map((project: any) => {
					    return (<ProjectCard
                            key={project?.id || 0}
							project={project}
							/>
						);
				    }) :
                    <p>No projects found</p>
                }
			</>
		);
	} 
}