import React from 'react';

import './prompt.css';

interface DialogProps {
    message: string,
    opened: boolean,
    onDialog: any, // funcao para a resposta
    type: string // Se for "confirm" tens 2 butoes, se for "alert" tem so 1 
}

export default function Prompt({message, opened, onDialog, type}:DialogProps) {
    
    return (
        <div className='z-[999]'>
            {opened && <div className='fixed inset-0 bg-black opacity-30'></div>}
            <dialog open={opened} id='prompt' className='fixed top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-1/2 h-1/2 rounded-[18px]'>
                <div id='prompt-content' className='absolute flex flex-col justify-center items-center top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] gap-10'>
                    <h3 className='text-[var(--inverted)]'>{message}</h3>
                    <div className='w-full flex justify-center items-center gap-4'>
                        {
                            type === "confirm" ?
                            <>                            
                                <button className="w-[8rem] h-[2rem] bg-green rounded-full text-white"
                                    onClick={() => onDialog(true)}
                                >Yes</button>
                                <button className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-white"
                                    onClick={() => onDialog(false)}
                                >Cancel</button>
                            </>
                            :
                            <button className="w-[8rem] h-[2rem] bg-[var(--yellow)] rounded-full text-white"
                                onClick={() => onDialog(true)}
                            >OK</button>
                        }
                </div>
                </div>
            </dialog>
        </div>
    );
}