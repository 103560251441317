import React from "react";
import './header.css';

import { useParams } from "react-router-dom";
import projectData from "../../jsons/project.json";
import ProfileImg from "../../content/images/icons/bmw-logo.png";

function Header() {

    const { projectId = "1" } = useParams<{ projectId?: string }>();

    const [project, setProject] = React.useState<any>(null);

    // TODO - Get project from API
    React.useEffect(() => {
        const index = parseInt(projectId, 10) - 1;
        setProject(projectData.projects[index]);
    }, [projectId]);

    return (
        <div id="" className="card relative w-full flex milecem:flex-row flex-col justify-between items-center p-8 cinco:gap-[5rem] gap-[2rem]">
            <div className="flex gap-8 cinco:flex-row flex-col cinco:items-start items-center">
                <img src={ProfileImg} alt="profile" className="rounded-full w-[10rem]" />
                <div className="flex flex-col justify-center cinco:text-left text-center">
                    <p className="text-[1.5rem] font-bold">{project?.type !== "Training" ? project?.role : project?.name}</p>
                    <p className="text-[1.2rem]">{project?.company}</p>
                </div>
            </div>
            <div className="flex flex-col gap-4 text-left text-[1rem]">
                <p className="flex milecem:flex-row flex-col milecem:text-left text-center"><span className="text-[var(--soft-text)]">Area: </span>{project?.area}</p>
                <p className="flex milecem:flex-row flex-col milecem:text-left text-center"><span className="text-[var(--soft-text)]">{project?.type !== "Training" ? "Location:" : "Technology:"} </span>{project?.type !== "Training" ? project?.city + ", " + project?.country : project?.tech}</p>
                <p className="flex milecem:flex-row flex-col milecem:text-left text-center"><span className="text-[var(--soft-text)]">Type: </span><span className={`font-bold ${project?.type !== 'Training' ? 'text-green' : 'text-blue'}`}>{project?.type !== "Training" ? 'Vacancy' : 'Training'}</span></p>
            </div>
            <div className="flex gap-4">
                {project?.type === 'Vacancy' ?
                    <>
                        <div className="card w-[10rem] py-6 relative flex flex-col justify-center items-center p-3">
                            <p className="font-bold">Deadline</p>
                            <h2 className="text-[var(--stats)] text-h3">{project?.deadline || "..."}</h2>
                        </div>
                        <div className="card w-[10rem] py-6 relative flex flex-col justify-center items-center p-3">
                            <p className="font-bold">Fase</p>
                            <h2 className="text-[var(--stats)] text-h3">{project?.fase || "..."}</h2>
                        </div>

                    </>
                    :
                    <>
                        <div className="card w-[10rem] py-6 relative flex flex-col justify-center items-center p-3">
                            <p className="font-bold">Candidates</p>
                            <h2 className="text-[var(--stats)] text-h3">{project?.participants || "..."}</h2>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Header;
