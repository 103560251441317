import React, { useState } from "react";

import HeaderTop from '../../components/HeaderTop/header-top';
import Section from './section';
import { FilterProvider } from "../../components/Context/projectFilterContext";
import './explore.css'
import Toggle from "../../components/Toggle/Toggle";
import { useNavigate } from "react-router-dom";

function Explore() {
	const [section, setSection] = useState(1);
	const [search, setSearch] = useState('');
	const [companyName, setCompanyName] = useState("");
	const [area, setArea] = useState("");
	const [tech, setTech] = useState("");
	const [wage, setWage] = useState(0);
	const [workMethod, setWorkMethod] = useState("All");
	const [contractType, setContractType] = useState("All");
	const [deadline, setDeadline] = useState("Default");

	const navigate = useNavigate();

	const applyFilters = () => {
		let queryParams = new URLSearchParams();

		if (companyName) queryParams.append("companyName", companyName);
		if (area) queryParams.append("area", area);
		if (tech) queryParams.append("tech", tech);
		if (wage) queryParams.append("wage", wage.toString());
		if (workMethod !== "All") queryParams.append("workMethod", workMethod);
		if (contractType !== "All") queryParams.append("contractType", contractType);
		if (deadline !== "Default") queryParams.append("deadline", deadline);

		navigate(`${window.location.pathname}?${queryParams.toString()}`);
		// setShowFilters(false);
	};

	const resetFilters = () => {
		setCompanyName("");
		setArea("");
		setTech("");
		setWage(0);
		setWorkMethod("All");
		setContractType("All");
		setDeadline("Default");
		navigate(`${window.location.pathname}`);
	};

	function Filters() {
		return (
			<div className="card relativ z-[5] p-5 flex-col gap-4 w-[23rem] h-[37rem] hidden seis:flex">
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Search company:</h4>
					<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={companyName} onChange={e => { setCompanyName(e.target.value) }} />
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Search area:</h4>
					<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={area} onChange={e => { setArea(e.target.value) }} />
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Search technology:</h4>
					<input type="text" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" value={tech} onChange={e => { setTech(e.target.value) }} />
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Min Wage:</h4>
					<input type="number" className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.2rem] w-full rounded-full border-2 border-blue text-p text-white" placeholder="|" min="0" onChange={e => { setWage(parseFloat(e.target.value) || 0) }} />
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Method of Work:</h4>
					<select value={workMethod} onChange={e => setWorkMethod(e.target.value)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.3rem] w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
						<option className="relative bg-blue" value="All">All</option>
						<option className="relative bg-blue" value="Hybrid">Hybrid</option>
						<option className="relative bg-blue" value="Remote">Remote</option>
						<option className="relative bg-blue" value="In Office">In Office</option>
					</select>
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Contract Type:</h4>
					<select value={contractType} onChange={e => setContractType(e.target.value)} className="relative bg-[var(--inputbg)] outline-0 px-4 py-[.3rem] w-full rounded-full border-2 border-blue text-p text-white cursor-pointer">
						<option className="relative bg-blue" value="All">All</option>
						<option className="relative bg-blue" value="Full-time">Full-time</option>
						<option className="relative bg-blue" value="Part-time">Part-time</option>
						<option className="relative bg-blue" value="Project-only">Project-only</option>
					</select>
				</div>
				<div className="relative flex flex-col">
					<h4 className="text-h5 text-left w-full mb-2">Deadline:</h4>
					<div className="relative flex gap-4">
						<div className="relative flex items-center gap-2">
							<p className="text-h5">Default</p>
							<input id="filters-deadline-default" checked={deadline === "Default"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("Default")} />
						</div>
						<div className="relative flex items-center gap-2">
							<p className="text-h5">&gt; 1 month</p>
							<input id="filters-deadline-more" checked={deadline === "more"} type="checkbox" className="filters-type rounded-full bg-[#ddd] " onChange={() => setDeadline("more")} />
						</div>
					</div>
				</div>
				<div className='flex gap-4'>
					<button onClick={applyFilters} className='bg-blue w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>apply</button>
					<button onClick={resetFilters} className='bg-[var(--yellow)] text-black w-full h-[2rem] text-[1rem] flex items-center justify-center rounded-full'>reset</button>
				</div>
			</div>
		)
	}

	return (
		<div id='explore' className='relative flex flex-col w-full mr-4 mb-4 min-h-screen'>
			<HeaderTop filters={true} searchBar={true} page={"explore"} search={search} setSearch={setSearch} />
			<div id="explore-header-navbar" className="relative flex items-center mt-4">
				<div className="w-[23rem] hidden justify-center seis:flex">
					<button onClick={() => window.location.href = "/agent/projects"} className="relative bg-[var(--yellow)] w-[7.5rem] py-[3px] rounded-full text-black">My Jobs</button>
				</div>
				<div className="w-full flex justify-center">
					<Toggle section={section} changeSection={setSection} first="Vacancy" second="Training" />
				</div>
			</div>
			<div className="explore-page relative flex justify-center w-full mt-8 gap-4">
				<Filters />
				<div className="explore-list max-h-[100vh] overflow-y-scroll">
					<FilterProvider>
						<Section section={section} search={search} />
					</FilterProvider>
				</div>
			</div>
		</div>
	);
}

export default Explore;
