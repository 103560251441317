import React from 'react';
import HeaderTop from '../HeaderTop/header-top';
import ProjectHeader from '../Header/project-header';
import WorldMap from '../WorldMap/worldmap';
import Users from './users.json';
import projectData from "../../jsons/project.json";
import { useNavigate, useParams } from 'react-router-dom';

export default function ProjectStats() {

    const users = Users.users;

    const navigate = useNavigate();
    const { projectId = "1" } = useParams<{ projectId?: string }>();

    const [project, setProject] = React.useState<any>(null);

    // TODO - Get project from API
    React.useEffect(() => {
        const index = parseInt(projectId, 10) - 1;
        setProject(projectData.projects[index]);
    }, [projectId]);

    function ProjectInfo() {

        type UserCardProps = {
            index: number;
            username: string;
            level: string;
            nextLevel: string;
            Wage: string;
        };

        function UserCard({ index, ...props }: UserCardProps) {
            return (
                <div className='invert-card w-full h-[4rem] flex justify-around items-center'>
                    <p className='text-yellow'>{index + 1}</p>
                    <p>@{props.username}</p>
                    <p className='text-[var(--stats)]'>{props.level}.{props.nextLevel}%</p>
                    <p>{props.Wage}$</p>
                    <div className='w-5 h-5 bg-blue border-2 border-[transparent] hover:border-white transition-all duration-200 rounded-full cursor-pointer'></div>
                </div>
            );
        }

        return (
            <div className='w-full flex flex-col mil:flex-row mt-4 gap-4'>
                <div className='card flex flex-col w-full h-[25rem] p-6 overflow-y-scroll'>
                    <h2 className='text-h4 font-bold text-left mb-8'>Participants</h2>
                    <div className='flex justify-around pl-4'>
                        <p>#</p>
                        <p>username</p>
                        <p>level</p>
                        <p>Wage</p>
                        <p>ver</p>
                    </div>
                    <div className='flex flex-col gap-4'>
                        {
                            users.length === 0 ? <p>No participants in this project</p> :
                                users.map((card, index) => (
                                    <UserCard key={index} index={index} {...card} />
                                ))
                        }
                    </div>
                </div>
                <div className='card w-full h-[25rem] p-6'>
                    <h2 className='text-h4 font-bold text-left mb-4'>Global Stats</h2>
                    <WorldMap />
                </div>
            </div>
        );
    }

    return (
        <div className='min-h-screen mb-4'>
            <HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null} />
            <ProjectHeader />
            <div className="card w-full px-10 py-5 my-4 flex justify-between items-center">
                <div className="flex gap-20 font-bold">
                    <p>{project?.contract}</p>
                    <p>{project?.method}</p>
                </div>
                <div>
                    <button onClick={() => navigate(`/company/projects/${project?.id}/page`)} className="relative bg-[var(--yellow)] w-[9rem] py-[3px] rounded-full text-black">project page</button>
                </div>
            </div>
            <ProjectInfo />
        </div>
    );
}

