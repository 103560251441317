import React, { useEffect } from 'react';
import {Navbar, OtherProfile, Profile, Projects, Jobs, ProjectPage, ProjectStats, EditProject, CreateProject, Settings, NotFoundPage} from './company/index';
import {Route, Routes, useLocation} from 'react-router-dom';
import { UserDataProvider } from './components/Context/agentContext';
import Footer from './components/Footer/footer';
// import { RequireAuth } from 'react-auth-kit';

function Company() {

  const routes = [
    {
      path: "/:username",
      element: <OtherProfile />
    },
    {
      path: "/profile",
      element: <Profile />
    },
    {
      path: "/projects",
      element: <Projects />
    },
    {
      path: "/jobs",
      element: <Jobs />
    },
    {
      path: "/projects/:projectId/page",
      element: <ProjectPage />
    },
    {
      path: "/projects/:projectId",
      element: <ProjectStats />
    },
    {
      path: "/projects/:projectId/edit/",
      element: <EditProject />
    },
    {
      path: "/create/",
      element: <CreateProject />
    },
    {
      path: "/settings",
      element: <Settings />
    },
    {
      path: "/settings/:settingsPage",
      element: <Settings />
    },
  ]
  
  const location = useLocation();

	useEffect(() => {
		document.getElementById('navbar')?.classList.remove('active');
		document.getElementById('menu-btn')?.classList.remove('active');
		document.body.style.overflow = 'auto';
		window.scroll(0, 0);
	}, [location]);


  return (
    <UserDataProvider>
        <div id='company'  className='relative flex flex-col text-center font-GilroyLight min-h-[100vh] w-full pr-4'>
          <div className='flex'>
            <Navbar/>
            <div className='page relative pl-[8rem] w-full'>
              <Routes>
                <Route index element={<Profile/>}></Route>
                {routes.map((route) => {
                  return (
                    <Route
                      key={route.path}
                      path={route.path} 
                      element={route.element}>
                    </Route>
                  )
                })}
                <Route path="*" element={<NotFoundPage/>} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
    </UserDataProvider>
  );
}

export default Company;
