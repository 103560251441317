import './header-top.css';

import Lupa from "../../content/images/icons/lupa.png";
import Profile from "../../content/images/others/profile.jpg";
import ProjectFilters from "./components/project-filters";
import CoursesFilters from "./components/courses-filters";
import { useNavigate } from 'react-router-dom';
import { FiPaperclip } from '@react-icons/all-files/fi/FiPaperclip';
import ExploreFilters from './components/explore-filters';
import Cookies from "js-cookie";

// import CoursesFilters from "./components/courses-filters";

// import Cookies from "js-cookie";
// TODO: fix this
// const savedUserData = Cookies.get('userData');
// const userData = savedUserData ? JSON.parse(savedUserData) : null;
// const Profile = userData.profileImg;

interface Props {
    filters: boolean;
    searchBar: boolean;
    page: string;
    search: string;
    setSearch: (value: string) => void;
}

function HeaderTop({ filters, searchBar, page, search, setSearch }: Props) {

    const navigate = useNavigate();

    const toggleMenu = () => {
        const navbar = document.getElementById('navbar');
        const menuBtn = document.getElementById('menu-btn');
        
        navbar?.classList.toggle('active');
        menuBtn?.classList.toggle('active');
        if (navbar?.classList.contains('active')) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };
    

    function MenuButton() {
        return (
            <div onClick={toggleMenu} id="menu-btn" className="relative w-[20px] h-7 flex flex-col justify-evenly items-center z-[100]">
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
                <div className="menu-btn-bar bg-[var(--text)] relative w-full h-[3px]"></div>
            </div>
        );
    }

    const filtersList: { [key: string]: JSX.Element } = {
        projects: <ProjectFilters />,
        explore: <ExploreFilters />,
        courses: <CoursesFilters />
    };

    const pathSegments = window.location.pathname.split("/").filter(Boolean);
    
    return (
        <div id="header-top" className="relative flex justify-between items-center w-full pr-4 my-4 gap-4">
            <div id="header-top-left" className="relative w-full flex items-center">
                {filters ? filtersList[page] : null}
                {searchBar ?
                    <div id="header-search" className="card relative flex justify-start items-center">
                        <input
                            type="text"
                            placeholder={page !== "profile" ? page + "..." : "agents/companies..."}
                            value={search}
                            id='search-input'
                            onChange={(e) => setSearch(e.target.value)}
                            className="relative w-full ml-5"
                        />
                        <img src={Lupa} alt="lupa" className="absolute left-5 invert w-5" />
                    </div>
                    :
                    <div className='relative pl-4 flex items-center pt-2'>
                        <p>
                            {pathSegments.map((segment, index) => {
                                if (index === 0) return null;
                                const isLast = index === pathSegments.length - 1;
                                const href = `/${pathSegments.slice(0, index + 1).join("/")}`;

                                return (
                                    <span key={index}>
                                        {index > 0 && " / "}
                                        <a
                                            href={href}
                                            className={isLast ? 'text-[var(--yellow)]' : 'hover:underline cursor-pointer'}
                                        >
                                            {segment}
                                        </a>
                                    </span>
                                );
                            })}
                        </p>
                    </div>
                }
            </div>
            <div id="header-top-right" className="relative flex justify-end items-center w-1/4 gap-4">
                {
                    page === "profile" && Cookies.get("userData") === 'agent' ?
                        <div onClick={() => navigate('/agent/settings/cv')} id='header-cv' className='card flex gap-4 h-[3rem] w-[10rem] justify-center items-center rounded-full cursor-pointer'>
                            <FiPaperclip color='var(--text)' className='w-6 h-6' />
                            <p>my CV</p>
                        </div> : null
                }
                <div id="header-foto" className="relative flex justify-center items-center gap-4 hover:cursor-pointer" onClick={() => navigate('/agent/settings')}>
                    <img src={Profile} alt="img" className="rounded-full" />
                </div>
            </div>
            <MenuButton />
        </div>
    );
}

export default HeaderTop;
