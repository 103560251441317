import React from "react";

import { UserDataProvider } from "../Context/agentContext";
import HeaderTop from '../HeaderTop/header-top';
import Header from '../Header/project-header';
import CoursesRecommended from "./recommendedCourses";

// import Profile from "../../content/images/others/profile.jpg";
import './projects.css';
import { useNavigate, useParams } from "react-router-dom";
import { useUserData } from '../Context/agentContext';
// import { GetProject } from "../../services/projects";
import projectData from "../../jsons/project.json";

function ProjectPage() {

	const { user } = useUserData();
	const navigate = useNavigate();
	const { projectId = "1" } = useParams<{ projectId?: string }>();

	const [project, setProject] = React.useState<any>(null);

	// TODO - Get project from API
	React.useEffect(() => {
		const index = parseInt(projectId, 10) - 1;
		setProject(projectData.projects[index]);
	}, [projectId]);

	// React.useEffect(() => {
	// 	GetProject(projectId, (projectData:ProjectInterface) => {
	//         setProject(projectData);
	//     });
	// }, [projectId]);

	if (!project) return <div>Loading...</div>;

	function handleSubscribe() {
		navigate(`/agent/projects/${parseInt(projectId, 10)}/${user?.username}`);
	}

	function ProjectInfo() {
		return (
			<div id="project-info" className={`relative w-full h-full flex gap-4`}>
				<div className="card flex flex-col gap-4 w-full h-full p-6 text-left justify-start">
					<h4 className="text-h4 mb-6 text-left">Project Info</h4>
					<div className="relative flex w-full justify-start gap-4 flex-col">
						<div className="relative flex flex-col w-full">
							<p className="text-h5 mb-4">Description:</p>
							<div className="relative text-white p-6 border-2 border-blue bg-[var(--inputbg)] rounded-[18px] w-full ">
								<p id="project-info-desc" className="text-h5">{project?.descr || "Loading..."}</p>
							</div>
						</div>
						{
							project?.type === "Vacancy" &&
							<>
								<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
									<h5 className="text-h5 w-full mb-2">Area:</h5>
									<h4 className="text-h4"><b>{project?.area || "Loading..."}</b></h4>
								</div>
								<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
									<h5 className="text-h5 w-full mb-2">Technology:</h5>
									<h4 className="text-h4"><b>{project?.tech || "Loading..."}</b></h4>
								</div>
							</>
						}
						<div className="flex w-full justify-center">
							{user?.name === project?.company ? <button onClick={() => navigate(`/company/projects/${projectId}/edit`)} className="relative bg-[var(--yellow)] w-[7.5rem] py-[3px] rounded-full text-white">edit</button> :
								project?.subscribed ?
									<button onClick={() => navigate(`/agent/projects/${projectId}/${user?.username}`)} className="relative bg-green w-[7.5rem] py-[3px] rounded-full text-white">my project</button> :
									<button onClick={() => handleSubscribe()} className="relative bg-green w-[7.5rem] py-[3px] rounded-full text-white">subscribe</button>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}

	function VacancyInfo() {
		return (
			<div id="vacancy-info" className="card relative flex flex-col w-full min-h-[20rem] p-6">
				<h4 className="text-h4 mb-6 text-left">Vacancy Info</h4>
				<div className="relative flex w-full justify-center gap-4 mt-4 flex-col">
					<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
						<h5 className="text-h5 w-full mb-2">Wage:</h5>
						<h4 className="text-h4"><b>${project?.minWage || "..."} - ${project?.maxWage || "..."}</b></h4>
					</div>
					<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
						<h5 className="text-h5 w-full mb-2">Work method:</h5>
						<h4 className="text-h4"><b>{project?.method || "Loading..."}</b></h4>
					</div>
					<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
						<h5 className="text-h5 w-full mb-2">Contract type:</h5>
						<h4 className="text-h4"><b>{project?.contract || "Loading..."}</b></h4>
					</div>
					<div className="invert-card relative flex flex-col justify-center items-center text-center p-5">
						<h5 className="text-h5 w-full mb-2">Location:</h5>
						<h4 className="text-h4"><b>{project?.country || "Loading..."}, {project?.city || "Loading..."}</b></h4>
					</div>
				</div>
			</div>
		);
	}

	function SubscribeInfo() {
		return (
			<div id="subscribe-info" className="card w-full h-full p-6 text-left flex flex-col gap-8">
				<h1 className="text-h3"><b>{project?.type === "Vacancy" ? "Apply" : "Subscribe" || "Loading..."}</b></h1>
				<div id="project-info-btns" className="invert-card relative flex justify-center gap-5 p-5 py-8">
					<button className="relative bg-blue w-[7.5rem] py-[3px] rounded-full text-white">share</button>
					{user?.name === project?.company ? <button onClick={() => navigate(`/company/projects/${projectId}/edit`)} className="relative bg-[var(--yellow)] w-[7.5rem] py-[3px] rounded-full text-white">edit</button> :
						project?.subscribed ?
							<button onClick={() => navigate(`/agent/projects/${projectId}/${user?.username}`)} className="relative bg-green w-[7.5rem] py-[3px] rounded-full text-white">my project</button> :
							<button onClick={() => handleSubscribe()} className="relative bg-green w-[7.5rem] py-[3px] rounded-full text-white">subscribe</button>
					}
				</div>
				<div className="relative flex flex-col gap-3">
					<h2 className="text-h4 text-[var(--yellow)]">Subscribe</h2>
					<p className="text-p">You can only subscribe to this {project?.type === "Vacancy" ? "vacancy" : "project" || "Loading..."} once, if you give up and subscribe again this will no longer be valid. When you do subscribe, make sure you check every file and information given.</p>
				</div>
				<div className="relative flex flex-col gap-3">
					<h2 className="text-h4 text-[var(--yellow)]">Submit</h2>
					<p className="text-p">You must publish {project?.type === "Vacancy" ? "the requirements" : "your project" || "Loading..."} until the deadline or your work won't be graded.</p>
				</div>
				{project?.type === "Vacancy" ? null :
					<div className="relative flex flex-col gap-3">
						<h2 className="text-h4 text-[var(--yellow)]">Evaluation</h2>
						<p className="text-p">After the deadline, those who did the project must evaluation other agents. If you don't, you won't get graded. After that, our team is going to evaluate your project and give you a grade.</p>
					</div>
				}
			</div>
		);
	}

	function ProjectCards() {
		return (
			<div id="project-page-cards" className={`relative w-full h-full gap-x-4 my-4 ${project?.type === "Vacancy" ? "" : "non-vacancy"}`}>
				{
					project?.type === "Vacancy" ? <VacancyInfo /> : null
				}
				<ProjectInfo />
				<SubscribeInfo />
			</div>
		);
	}
	return (
		<UserDataProvider>
			<div className="projects-page relative w-full h-full flex flex-col justify-start items-start">
				<HeaderTop filters={false} searchBar={false} page={""} search={""} setSearch={() => null} />
				<Header />
				<ProjectCards />
				<CoursesRecommended />
			</div>
		</UserDataProvider>
	);
}

export default ProjectPage;