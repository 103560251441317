import React from "react";
import './header.css';
import { Circle } from '../../../components/CircleProgressBar/circle';
import { useUserData } from "../../../components/Context/agentContext";
import ProjectChart from "./projectChart";

function EvalItem({...props}) {
    let active = props.deadline <= 1 ? "close-deadline" : "";
    return (
      <div onClick={() => window.location.replace('/agent/projects/projectName/other-username/eval')} className={"eval-item relative flex justify-between items-center w-full h-[4rem] text-left px-4 rounded-[18px] mb-5 cursor-pointer " + active}>
        <div className="relative flex flex-col">
          <h3 className="text-h4">{props.name}</h3>
          <h3 className="text-h5">from {props.user}</h3>
        </div>
        {props.deadline <= 1 ? <h2 className='relative'><span>1</span> day left</h2> : null}
        <div  className="eval-item-arrow relative flex flex-col justify-between items-center">
          <h1>→</h1>
        </div>
      </div>
    );
  }
  
  function Evaluations() {
    const { user } = useUserData();
    return (
      <div id="evaluations-card" className="relative invert-card flex flex-col justify-start items-center px-7 pt-5">
        <h4 className="text-h4 text-right w-full mb-6 font-bold">Evaluate others</h4>
        <div id="evaluations-list" className="relative flex flex-col w-full">
          {user?.evaluations.map((evalu:any) => {
              return <EvalItem key={evalu.id} name={evalu.name} user={evalu.user} deadline={evalu.deadline}/>
            })
          }
        </div>
      </div>
    );
  }

function Header() {
  const { user } = useUserData();
  return (
      <div id="projects-header" className="relative flex flex-col justify-between items-center">
        <div id="projects-header-stats" className="card relative flex justify-between items-center px-7 py-5 w-full gap-4">
            <div id="projects-header-chart" className="relative h-full w-[40%]">
              <ProjectChart />
            </div>
            <div id="projects-header-userstats" className="relative w-[50%] h-full flex justify-center items-center">
              <Circle level={user?.level || 0} nextLevel={user?.nextLevel || 0} width={200} complete={user?.nextLevel || 0}/>
            </div>
            <div id="projects-header-evaluations" className="invert-card top-5 right-7 absolute w-[35%] h-[15rem]">
              <Evaluations/>
            </div>
        </div>
      </div>
  );
}

export default Header;

