import React, { useState, useEffect } from "react";
import '../../components/navbar/navbar.css';
import {NavLink} from 'react-router-dom';

import Logo from "../../content/images/logos/logo.png";
// import Moon from "../../content/images/icons/moon.png";
// import Sun from "../../content/images/icons/sun.png";
// import Personal from "../../content/images/icons/personal.png";
// import Projects from "../../content/images/icons/project.png";
// import Courses from "../../content/images/icons/icon-courses.png";
// import Certified from "../../content/images/icons/certified.png";
// import Settings from "../../content/images/icons/icon-settings.png";

import {GiAchievement} from '@react-icons/all-files/gi/GiAchievement';
import {BsFillPersonFill} from '@react-icons/all-files/bs/BsFillPersonFill';
import {CgMenuGridR} from '@react-icons/all-files/cg/CgMenuGridR';
import {MdGolfCourse} from '@react-icons/all-files/md/MdGolfCourse';
import {IoMdSettings} from '@react-icons/all-files/io/IoMdSettings';
import {MdWbSunny} from '@react-icons/all-files/md/MdWbSunny';
import {RiMoonClearFill} from '@react-icons/all-files/ri/RiMoonClearFill';
import {BiWorld} from '@react-icons/all-files/bi/BiWorld';

function Navbar() {
  const [isLightMode, setIsLightMode] = useState(false);

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === 'light') {
      setIsLightMode(true);
      document.body.classList.add("light-mode");
    }
  }, []);

  function changeTheme() {
    if (isLightMode) {
      document.body.classList.remove("light-mode");
      localStorage.removeItem('theme');
    } else {
      document.body.classList.add("light-mode");
      localStorage.setItem('theme', 'light');
    }
    setIsLightMode(!isLightMode);
  }

  interface CustomLinkProps {
    href: string;
    icon?: React.ComponentType<{ width?: number }>; 
    children: React.ReactNode;
  }

  const CustomLink: React.FC<CustomLinkProps> = ({ href, icon:Icon, children }) => {
    let selected = href === window.location.pathname || (href === '/agent/projects' && window.location.pathname.includes('/agent/projects/'));
    return (
          <NavLink to={href} className={({ isActive }) => isActive ? "selected navbar-link group flex items-center" : "navbar-link group flex items-center"}>
            <div className={`${selected ? "bg-blue" : ""} p-2 rounded-[8px]`}>
              {Icon && <span className={`icon-wrapper ${selected ? "text-white" : "text-[var(--soft-text)] group-hover:text-[var(--text)]"}`} ><Icon /></span>}
            </div>
            <p className={`relative ml-4 text-[.9rem] hidden  ${selected ? "text-[var(--text)]" : "text-[var(--soft-text)] group-hover:text-[var(--text)]"}`}>{children}</p>
          </NavLink>
    );
  };

  function initTheme() {
    var darkThemeSelected = (localStorage.getItem('theme') !== null && localStorage.getItem('theme') === 'light');
    darkThemeSelected ? document.body.classList.add('light-mode') : document.body.classList.remove('light-mode');
  };

  initTheme();

  return (
    <div 
      id="navbar" 
      className={`peer navbar-hover card fixed flex justify-center items-center z-50 w-[6rem] h-[96%] m-4 hover:w-[13rem] transition-all duration-500 ease-in-out`}
    >
        <div id="navbar-list" className="relative flex flex-col justify-between items-start h-full w-full p-5">
          <div className="relative flex flex-col justify-start items-start w-full">
            <div className="navbar-icon relative flex justify-center items-center w-full mb-5">
              <div id="navbar-icon" className="navbar-link-logo relative flex justify-center items-center cursor-pointer h-[2rem]">
                <h2 className="text-h4"><b>W</b></h2>
                <img src={Logo} alt="img" className={`cursor-pointer w-32 hidden ${localStorage.getItem('theme') === 'light' ? "invert" : ""}`} onClick={() => window.location.replace('https://worklab.pt')}/>
              </div>
            </div>
            <div className="hr w-full relative flex justify-center align-center">&ensp;</div>
            <div className="flex flex-col gap-10 justify-start p-3 pt-6">
              <CustomLink href="/agent/profile" icon={BsFillPersonFill}>Profile</CustomLink>
              <CustomLink href="/agent/projects" icon={CgMenuGridR}>Projects</CustomLink>
              <CustomLink href="/agent/explore" icon={BiWorld}>Explore</CustomLink>
              <CustomLink href="/agent/courses" icon={MdGolfCourse}>Courses</CustomLink>
              <CustomLink href="/agent/achievements" icon={GiAchievement}>Achievements</CustomLink>
            </div>
          </div>
          <div className="relative flex flex-col justify-start items-start w-full gap-10 p-3">
            <div onClick={() => changeTheme()} className="navbar-link flex cursor-pointer items-center group">
              <div className="navbar-link-icon p-2">
                <span className="icon-wrapper text-[var(--soft-text)] group-hover:text-[var(--text)]" >{isLightMode ? <RiMoonClearFill/> : <MdWbSunny/>}</span>
              </div>
              <p className={`relative link ml-4 text-[.9rem] hidden group-hover:text-[var(--text)]`}>Theme</p>
            </div> 
            <CustomLink href="/agent/settings" icon={IoMdSettings}>Settings</CustomLink>
          </div>
        </div>
    </div>
  );
}

export default Navbar;
