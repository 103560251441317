import UserList from "../../jsons/usersList.json";

function UserCard({...props}) {
    return (
        <div onClick={() => window.location.replace(`/company/${props.username}`)} className="card relative w-[7rem] h-[9rem] flex flex-col justify-center items-center py-3 px-2 cursor-pointer">
            <div className="relative flex h-[60%] w-full rounded-full justify-center items-center">
                <img src={props.img} alt="profileImg" className="rounded-full w-[75%]"/>
            </div>
            <div className="relative flex w-full h-[40%] justify-center items-end">
                <p className="text-[.7rem]">{props.username}</p>
            </div>
        </div>
    );
}

function UserSearch({ search }:{search:string}) {
	let userList = UserList.users;
    // Filter based on search
	if (search !== '')
        userList = userList.filter((p:any) => p.username.toLowerCase().includes(search?.toLowerCase()));
    return (
        <div className="flex flex-wrap gap-4 w-full justify-start items-start min-h-screen">
            { userList?.length !== 0 ?
                userList?.map((user: any) => {
                return (<UserCard
                    key={user?.id || 0}
                    id={user?.id || 0}
                    username={user?.username || "Loading..."}
                    img={user?.image || "Loading..."}
                />);
                })
                :
                <p>No users found</p>
            }
        </div>
    );
}

export default UserSearch;