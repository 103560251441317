import axios from "axios";

const URL = process.env.URL+"/projects"

const getProject = (id, callback) => {
    axios
        .get(URL + `/${id}`)
        .then((res) => {
            // console.log(res);
            callback(res.data);
        })
        .catch((error) => {
            console.error("Get project failed:", error);
        });
};

const getProjects = (limit, offset) => {
    axios
        .get(URL, {params: {limit, offset}})
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful get projects response here
            // To Project page
            /**
             * [
             *   {
             *     "id": 1,
             *     "name": "Test Project",
             *     "description": "A test project",
             *     "subject": "Test Subject",
             *     "deadline": "2023-12-31",
             *     "num_users": 1,
             *     "type": "job",
             *     "createdAt": "2023-01-01T00:00:00Z",
             *     "updatedAt": "2023-01-02T00:00:00Z",
             *     "company_id": 1
             *   }
             * ]
             */
        })
        .catch((error) => {
            console.error("Get projects failed:", error);
            // Handle login error here
        });
};

const deleteProject = (id) => {
    axios
        .delete(URL + `/${id}`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful delete project response here
            // To Project page
            /**
             * If success, return 204
             */
        })
        .catch((error) => {
            console.error("Delete project failed:", error);
            // Handle login error here
        });
};

const CreateProject = (projectData, onSuccess, onError) => {
    axios
        .post(URL, projectData)
        .then((res) => {
            console.log(res);
            if (res.status === 201) {
                onSuccess(res);
            } else {
                onError(res);
            }
        })
        .catch((error) => {
            console.error("Create project failed:", error);
            onError(error);
        });
};

const UpdateProject = (projectData, onSuccess, onError) => {
    axios
        .post('/api/project/update', projectData)
        .then((res) => {
            if (res.data.success) {
                onSuccess(res);
            } else {
                onError(res);
            }
        })
        .catch((error) => {
            console.error("Update project failed:", error);
            onError(error);
        });
};

const joinProject = (id) => {
    axios
        .post(URL + `/${id}/join`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful join project response here
            // To Project page
            /**
             * If success, return 200
             */
        })
        .catch((error) => {
            console.error("Join project failed:", error);
            // Handle login error here
        });
};

const unSubscribeProject = (id) => {
    axios
        .post(URL + `/${id}/join`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful unsubscribe project response here
            // To Project page
            /**
             * If success, return 200
             */
        })
        .catch((error) => {
            console.error("Unsubscribe to project failed:", error);
            // Handle login error here
        });
};

const submitProject = (id, projectId, file) => {
    axios
        .post(URL + `/${id}/join`)  
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful project submition response here
            // To Project page
            /**
             * If success, return 200
             */
        })
        .catch((error) => {
            console.error("Submit project failed:", error);
            // Handle login error here
        });
};

const submitProjectEval = (id, answers, emptyWork, comment) => {
    axios
        .post(URL + `/${id}/join`)
        .then((res) => {
            console.log(res);
            /* TODO */
            // Handle successful eval submition response here
            // To Project page
            /**
             * If success, return 200
             */
        })
        .catch((error) => {
            console.error("Join project failed:", error);
            // Handle login error here
        });
};

export const GetProject = getProject;
export const GetProjects = getProjects;
export const createProject = CreateProject;
export const updateProject = UpdateProject;
export const DeleteProject = deleteProject;
export const JoinProject = joinProject;
export const SubmitProjectEval = submitProjectEval;
export const UnsubscribeProject = unSubscribeProject;
export const SubmitProject = submitProject;