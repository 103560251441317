import React, { useState } from "react";
import '../settings.css';

import Prompt from "../../../components/Prompt/prompt";
import { updateCompanyPassword } from "../../../services/companies";

function SecuritySettings() {

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleInputChange = (setStateFn: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
		setStateFn(e.target.value);
	};

	const [prompt, setPrompt] = useState({
		message: "",
		load: false,
		action: ""
	});

	function updateInfo() {
		let key1 = document.getElementById('settings-password') as HTMLInputElement;
		let key2 = document.getElementById('settings-password2') as HTMLInputElement;
		let error = document.getElementById('settings-error-msg') as HTMLParagraphElement;
		if (key1.value === "") {
			error.innerText = "Please write your new password.";
			return ;
		}
		if (key1.value !== key2.value) {
			error.innerText = "Make sure both passwords are the same.";
			return ;
		}
		updateCompanyPassword(
			{ newPass: password, confirmNewPass: confirmPassword },
			(response:any) => {
			  // Handle success
			  setPrompt({
				message: "Personal info updated successfully!",
				load: true,
				action: "save"
			  });
			  error.innerText = "";
			},
			(error:any) => {
			  // Handle error
			  error.innerText = error.response?.data?.message || "Error updating info!";
			}
		);  
		key1.value = "";
		key2.value = "";
		error.innerText = "";
	}
	

  return (
    <div id="profile-settings" className="settings-section relative flex flex-col w-full h-full px-10">
      	<div className="relative flex flex-col w-full h-full">
			<h1 className="text-h3 text-left mb-8">Password update</h1>
			<div id="settings-personal-info" className="settings-security-info relative flex flex-col text-left w-full mb-10">
			<div className="flex mr-8 items-center gap-4">
					<p className="text-h5">New password</p>
					<input type="password" value={password} onChange={e => handleInputChange(setPassword)(e)} />
				</div>
				<div className="flex mr-8 items-center gap-4">
					<p className="text-h5">Confirm Password</p>
					<input type="password" value={confirmPassword} onChange={e => handleInputChange(setConfirmPassword)(e)} />
				</div>
				<p id="settings-error-msg" className="text-left w-full text-red mb-4"></p>
			</div>
			<p id="settings-error-msg" className="text-left w-full text-red mb-4"></p>
			<div id="profile-settings-btn" className="w-full flex justify-start mb-4">
				<button onClick={updateInfo} className="bg-blue px-8 py-1 rounded-full text-white">Save</button>
			</div>
      	</div>
		<Prompt message={prompt.message} opened={prompt.load} onDialog={() => setPrompt({message: "", load: false, action: ""})} type="alert" />
    </div>
  );
}

export default SecuritySettings;
