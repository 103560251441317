// UserDataContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";

import fetchUserData from "../FetchData/fechUserData";

interface UserDataContextProps {
  user: any;
  setUser: React.Dispatch<React.SetStateAction<any>>;
}

const UserDataContext = createContext<UserDataContextProps | undefined>(undefined);

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error("useUserData must be used within a UserDataProvider");
  }
  return context;
};

interface UserDataProviderProps {
    children: React.ReactNode;
}
  
export const UserDataProvider: React.FC<UserDataProviderProps> = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        fetchUserData().then(data => {
          setUser(data);
        });
    }, []);

    return (
        <UserDataContext.Provider value={{ user, setUser }}>
            {children}
        </UserDataContext.Provider>
    );
};
