import React, { useEffect, useState } from "react";

import Logo from "../../content/images/logos/logo.png";

import AgentForm from './agentForm';
import CompanyForm from './companyForm';
import Footer from "../../components/Footer/footer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Signup() {

    const [form, setForm] = useState("agent");
    const navigate = useNavigate();

    function changeForm(n:string) {
        document.getElementById("button-agent")?.classList.toggle("active");
        document.getElementById("button-company")?.classList.toggle("active");
        setForm(n);
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    function autoSignup(type:string, href:string) {
        Cookies.set("userData", type);
        navigate(href);
    }

    return (
        <div id="signup" className="relative bg-no-repeat bg-cover flex flex-col w-full min-h-screen">
            <div className="relative flex flex-col gap-10 w-full px-[8vw] pt-5 justify-center-center z-[5]">
                <img onClick={() => window.location.replace("https://worklab.pt")} src={Logo} alt="logo" className="w-40 hover: cursor-pointer" />
                <div className="flex gap-6">
                    <p onClick={() => autoSignup("agent", "/agent/profile")} className="cursor-pointer">agent demo</p>
                    <p onClick={() => autoSignup("company", "/company/profile")} className="cursor-pointer">company demo</p>
                </div>
            </div>
            <div className="relative w-full h-full flex justify-center items-start">
                <div id="signup-container" className="relative flex flex-col items-center justify-start min-h-screen mt-10">
                    <div className="relative w-full mb-4">
                        <h1 className="text-h1 w-full text-center">Join Us</h1>
                        <p className="text-p mb-10 w-full text-center">This is a worklab demo</p>
                        <div className="relative flex justify-between items-center w-full mb-6">
                            <div id="button-agent" onClick={() => changeForm("agent")} className="sign-in-button active">Be an Agent</div>
                            <div id="button-company" onClick={() => changeForm("company")} className="sign-in-button">I am hiring</div>
                        </div>
                    </div>
                    {
                        form === "agent" ?
                        <AgentForm/> : <CompanyForm/>
                    }
                    <div id="signup-register-message" className="relative flex w-full justify-center items-center my-4">
                        <p className="text-p">Already have an account ? &ensp;<span onClick={() => navigate("/")} className="underline cursor-pointer">Login</span></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Signup;
