import React from 'react';

import './about.css';
import AboutData from './data.json';
import Footer from '../../components/Footer/footer';

export default function About() {
    return (
        <div>
            <p onClick={() => window.history.back()} className='absolute w-full top-0 left-0 py-5 px-5 text-left cursor-pointer  z-[5]'><span className='text-yellow'>←</span> &ensp; <span>Go back</span></p>
            <div id="about" className='relative w-full flex flex-col px-[15rem] text-left py-[5rem]'>
                <h1 className='text-h2 mb-16'>About Worklab</h1>
                <div className='relative flex flex-col gap-10'>
                    {
                        AboutData.data.map((section) => {
                            return (
                                <div key={section.id} className='relative w-full flex flex-col gap-5'>
                                    <h2 className='text-[var(--yellow)] text-h3'>
                                        {section.title}
                                    </h2>
                                    <p>
                                        {section.text}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
}
