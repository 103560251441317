import React from 'react';

interface Props {
    section: number,
    changeSection: (section: number) => void,
    first: string, 
    second: string
}

export default function Toggle({ section, changeSection, first, second }: Props) {
    return (
        <div id='toggle-button' className="relative card flex justify-center items-center w-full cinco:w-[25rem] h-[3rem]">
            <div className={`absolute w-[50%] h-[80%] rounded-full bg-blue mx-1 z-[0] transition-all duration-[500ms] ease-in-out ${section === 1 ? "translate-x-[-47%]" : "translate-x-[47%]"}`}></div>
            <div onClick={() => changeSection(1)} className={`w-full flex justify-center items-center text-[var(--soft-text)] z-[1] cursor-pointer hover:text-white  ${section === 1 ? "text-white" : "text-[var(--soft-text)]"}`}>
                <p className="">{first}</p>
            </div>
            <div onClick={() => changeSection(2)} className={`w-full flex justify-center items-center text-[var(--soft-text)] z-[1] cursor-pointer hover:text-white ${section === 2 ? "text-white" : "text-[var(--soft-text)]"}`}>
                <p className="">{second}</p>
            </div>
        </div>
    );
}

