import axios from "axios";
import Cookies from 'js-cookie';

const URL = process.env.URL+"/authentication"

const SubmitLogin = (email, password) => {
    return new Promise((resolve, reject) => {
        axios.post(URL + "/login", { email, password })
            .then((res) => {
                const userData = {
                    id: res.data.id || 1,
                    username:res.data.username,
                    token: res.data.token,
                    refreshToken: res.data.refreshToken,
                    profileImg: res.data.profileImg,
                };
                Cookies.set('userData', JSON.stringify(userData), { expires: 30 });
                resolve(); 
            })
            .catch((error) => {
                reject(new Error("Login failed. Please check your credentials."));
            });
    });
};
    
const Register = (firstName, lastName, email, username, country, city, password, passwordVerification) => {
    return new Promise((resolve, reject) => {
        axios
            .post(URL + "/register", { firstName, lastName, email, username, country, city, password, passwordVerification })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(new Error("Registration failed"));
                }
            })
            .catch((error) => {
                console.error("Register failed:", error);
                reject(error);
            });
    });
};

const RegisterCompany = (name, sector, email, username, country, city, password, passwordVerification) => {
    return new Promise((resolve, reject) => {
        axios
            .post(URL + "/register", { name, sector, email, username, country, city, password, passwordVerification })
            .then((res) => {
                if (res.status === 200) {
                    resolve(res.data);
                } else {
                    reject(new Error("Registration failed"));
                }
            })
            .catch((error) => {
                console.error("Register failed:", error);
                reject(error);
            });
    });
};
export const submitLogin = SubmitLogin;
export const register = Register;
export const registerCompany = RegisterCompany;
