import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useUserData } from '../../../components/Context/agentContext';

const getIntroOfPage = (label:string) => {
  if (label === 'Total') {
    return ["Number of vacancies in Worklab", "Number of projects in Worklab"];
  }
  if (label === 'Average') {
    return ["Average vacancies in Worklab", "Average projects in Worklab"];
  }
  if (label === 'Mine') {
    return ["Number of vacancies I opened", "Number of projects I created"];
  }
  return '';
};

const CustomTooltip = ({ ...props }) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label"><span className="intro">{getIntroOfPage(props.label)[0]}</span> : {`${props.payload[0].value}`}</p>
        <p className="label"><span className="intro">{getIntroOfPage(props.label)[1]}</span> : {`${props.payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

const ProjectChart = () => {
    const { user } = useUserData();

    let totalProjects = 304;
    let averageProjects = 27;
    let companyTotalProjects = user?.projects.length || 0;

    let totalVacancies = 548;
    let averageVacancies = 302;
    let companyTotalVacancies = user?.vacanciesOpened || 0;

    let data = [
        {
        name: 'Total',
        uv: 4000,
        amt: 2400,
        Vacancies: totalVacancies,
        Projects: totalProjects,
        },
        {
        name: 'Average',
        uv: 3000,
        amt: 2210,
        Vacancies: averageVacancies,
        Projects: averageProjects,
        },
        {
        name: 'Mine',
        uv: 2000,
        amt: 2290,
        Vacancies: companyTotalVacancies,
        Projects: companyTotalProjects,
        }
    ]
    return (
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 0,
            right: 40,
            bottom: 20,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Vacancies" barSize={25} fill="#f7c940" />
          <Bar dataKey="Projects" barSize={25} fill="#1B75F8" />
        </BarChart>
      </ResponsiveContainer>
    );
};

export default ProjectChart;