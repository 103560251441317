import React, {useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import Logo from "../../content/images/logos/logo.png";
import { submitLogin } from "../../services/authentication.js";

import './login.css';
import Footer from "../../components/Footer/footer";

function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();

    const handleEmailChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: any) => {
        setPassword(e.target.value);
    };

    function submit() {
        let error = document.getElementById('login-error-msg') as HTMLParagraphElement;

        if (!email || email === '' || !password || password === '') {
            error.innerText = "Please fill all the camps.";
            return ;
        }

        submitLogin(email, password)
        .then(() => {
            navigate("/agent/profile");
        })
        .catch(err => {
            error.innerText = err.message;
        });        
    }

  return (
      <div id="login" className="relative bg-no-repeat bg-cover flex flex-col w-full min-h-screen">
        <div className="absolute flex w-full pl-[8vw] pt-5 items-center z-[3]">
            <img onClick={() => window.location.replace("https://worklab.pt")} src={Logo} alt="logo" className="w-40 hover: cursor-pointer" />
        </div>
        <div className="relative w-full h-full flex justify-center items-center min-h-screen">
            <div id="login-container" className="relative flex flex-col items-center justify-between">
                <div className="relative w-full">
                    <h1 className="text-h1  mb-10 w-full text-center">Login</h1>
                    <div className="relative flex flex-col w-full">
                        <input
                            type="text"
                            placeholder="Username / Email"
                            className="login-input"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            className="signup-input"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <div className="relative flex w-full items-center justify-between">
                        <div className="flex items-center">
                            <input id="login-remeber-checkbox" type="checkbox" className="w-3 h-3 accent-black"/>
                            <p className="text-p ml-2">Remember me</p>
                        </div>
                        <button onClick={() => submit()} id="login-btn">Log in</button>
                    </div>
                </div>
                <p id="login-error-msg" className="text-red my-4"></p>
                <div id="login-register-message" className="relative flex w-full justify-center items-center">
                    <p className="text-p">Don't have an account ? &ensp;<Link to={'/signup'} className="underline cursor-pointer">Sign up</Link></p>
                </div>
            </div>
        </div>
        <Footer />
      </div>
  );
}

export default Login;
